<script>
import Vue from 'vue'
import api from '@/api'
import { treearr } from '@/tool'
import getConfig from '@/config'
import VueCookie from 'vue-cookie'
import LocalStorage from 'local-storage'

export default Vue.extend({
  components: {
  },
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      buttonloading: false,
      loading: true,
      getOrgData: false,
      typeEnums: [],
      fileList: [],
      imageUrl: '',
      ftpUrl: getConfig().ftpUrl,
      imgUpload: {
        show: false,
        uploadUrl: getConfig().uploadUrl,
        uploadHeaders: {
          'X-Auth-User': this.$store.state.user.loginName,
          'X-Auth-Token': this.$store.state.user.token
        }
      },
      operateLogList: [], // 操作记录
      warnList: [], /// 告警列表
      deviceInfoForm: {

      },
      orgOptions: [],
      categoryOptions: [],
      rules: {
        type: [
          { required: true, message: '机构类型', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入机构名称', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        personName: [
          { required: true, message: '请选择性别', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        phone: [
          { required: true, message: '请输入办公电话', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    isModeAdd () {
      return true
    },
    isModeEdit () {
      return false
    },
    isModeInfo () {
      return false
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.loading = false
    this.init()

    // 组织
    if (this.getOrgData) {
      api.auth.getLevelAllOrg().then(res => {
        // this.filter.orgOptions = this.tree(res.data)
        var sss = treearr(res.data)
        this.orgOptions = sss
      })
    }
  },
  beforeMount () {
  },
  mounted () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    init () {

    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = file.response.urls[0]
      console.log(file)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    getInfo () {

    },
    handlePreview (file) {
      if (file.url) {
        window.open(this.parseUrl(file.url), '_blank')
      } else {
        this.$message.error('附件地址错误')
      }
    },
    handleChangeFile (file, fileList) {
      // 传地址共享

      if (!file.url && file.response) {
        if (file.response.code === '1') {
          this.$message.error(file.response.desc)
          fileList.splice(fileList.length - 1, 1)
        }
        file.url = file.response.urls[0]
      }
      this.fileList = fileList
      if (this.deviceInfoForm) {
        this.deviceInfoForm.fileList = fileList
      }
    },
    submitForm (formName) {
      console.log('submitForm', formName)
    }
  }
})
</script>

<!--这里可能要放Global.scss-->
<style lang="scss" >
    @import "../assets/css/global-variable.scss";
    .img-upload {
        width: 100px;
        height: 100px;
        margin-left: 20px;
    }
    .g-role-add-box {
        position: relative;
        overflow: auto;
        border-radius: 5px;
    }
    .m-tt {
        margin-bottom: 30px;
        font-size: $fs-title;
        color: $fc-primary;
        font-weight: 500;
        line-height: 18px;
        text-indent: 10px;
        border-left: 4px solid $fc-theme;
        &.s-no-top {
            margin-top: 30px;
        }
    }
    .m-tt-t {
        margin-left: 100px;
        margin-bottom: 30px;
        font-size: $fs-title;
        color: $fc-primary;
        font-weight: 500;
        line-height: 18px;
        text-indent: 10px;
        border-left: 4px solid $fc-theme;
        &.s-no-top {
            margin-top: 30px;
        }
    }
    .lcline {
        margin-left: 100px;
    }
    .u-tag {
        margin-right: 10px;
        cursor: pointer;
    }
    .m-select {
        border: 1px solid rgb(185, 180, 180);
        border-radius: 4px;
        padding: 0 6px;
        height: 300px;
        .u-select-hd {
            border-bottom: 1px solid rgb(185, 180, 180);
            .u-select-hd-icon {
                float: right;
                margin-top: 10px;
                margin-right: 10px;
                cursor: pointer;
            }
        }
        .u-select-bt {
            overflow: auto;
            height: 260px;
        }
    }
    .s-h4 {
        margin-bottom: 20px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #409eff;
    }
    .avatar-uploader-icon {
        border: 1px solid #ddd;
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
