<template>
  <div class="g-box g-login-box">
    <span>获取菜单中...</span>
  </div>
</template>

<script>
import Vue from 'vue'
import api from './../../api'
import VueCookie from 'vue-cookie'
// import LocalStorage from 'local-storage'
export default Vue.extend({
  name: 'desk',
  components: {},
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      formInline: {
        page: {
          total: 0,
          pageSize: 10,
          currentPage: 1
        }
      },
      tabActive: 'account',
      ruleForm: {
        account: '',
        accountTips: '',
        password: '',
        passwordTips: '',
        rememberAccount: false,
        phone: '',
        phoneTips: '',
        verificationCode: '',
        verificationCodeState: false,
        countdown: 60,
        haloImg: '',
        logo: '',
        systemName: ''
      },
      filter: {
      },
      interval: {},
      homePath: ''
    }
  },
  computed: {

  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.init()
  },
  beforeMount () {
  },
  mounted () {
    // loadScene()
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    clearInterval(this.interval)
    this.deleteGlobalResize('login')
  },
  destroyed () {
  },
  methods: {
    init () {
      if (sessionStorage.getItem('_sid') && VueCookie.get('client-token')) { //
        var sid = sessionStorage.getItem('_sid')
        api.menu.querySecondMenuByParentId({ id: sid }).then(res => {
          if (res.data && res.data.length > 0) {
            var path = res.data[0].path
            this.$router.replace(path)
          } else {

          }
        })
      } else {
        this.$router.replace('/home')
      }
      // api.menu.queryHomePageMenuList({id:})
      // api.config.postUserMenu({
      //   type: '1'
      // }).then(res => {
      //   Vue.local.set('navData', res.data || [], 1000 * 60 * 60 * 24 * 31)
      //   if (res.data.length === 0) {
      //     this.$alert('该账号没有菜单 请先联系管理员配置', {
      //       confirmButtonText: '确定',
      //       callback: action => {
      //         VueCookie.delete('userId')
      //         // VueCookie.set('email', res.data.user.email || '')
      //         VueCookie.delete('client-token')
      //         VueCookie.delete('orgId') // 组织id
      //         VueCookie.delete('parkId') // 园区id
      //         // VueCookie.set('pageUrl', res.data.pageUrl || '')
      //         VueCookie.delete('role')
      //         localStorage.removeItem('navData');
      //         this.$store.commit('clearUser', {})

      //         api.auth.getAuthLogout({}).then(res => {})
      //         this.$router.replace('/login')
      //       }
      //     })
      //   } else {
      //     var path = ''
      //     // eslint-disable-next-line no-inner-declarations
      //     function findPath (arr, flag) {
      //       arr.forEach(ele => {
      //         if (!flag && ele.show) {
      //           if (!flag && ele.path && ele.path.indexOf('home') >= 0) {
      //             if (!flag && path === '') {
      //               flag = '/home'
      //               path = flag
      //             }
      //           } else if (!flag && ele.path && ele.path.indexOf('-list') >= 0) {
      //             if (!flag && path === '') {
      //               flag = ele.path
      //               path = flag
      //             }
      //           } else if (!flag && ele.node && ele.node.length > 0) {
      //             findPath(ele.node, flag)
      //           } else {
      //             if (!flag && path === '') {
      //               flag = ele.path
      //               path = flag
      //             }
      //           }
      //         } else if (!flag && ele.show && ele.node && ele.node.length > 0) {
      //           findPath(ele.node, flag)
      //         }
      //       })
      //     }
      //     findPath(res.data)
      //     // var sss='/authority-management/role/role-list'
      //     this.$router.replace(path)
      //   }
      // })
    }
  }
})
</script>

<style lang="scss" scoped>
@import "./../../assets/css/global-variable";
$lg-bg: "../../assets/img/login/lg-bg.png";
$lg-icon-account: "../../assets/img/login/icon-account.png";
$lg-icon-password: "../../assets/img/login/icon-password.png";
.g-login-box {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  //#canvas1{
  //  position:absolute;
  //  top:0;
  //  left:0;
  //  right:0;
  //  bottom:0;
  //  width: 100%;
  //  height: auto;
  //  z-index:0;
  //  background-color:#000;
  //}
  .c {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    .mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background: rgba(0, 0, 0, 0.6);
      background-size: 100%;
      z-index: 30;
    }
    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background: url("http://124.70.153.221:9998/static/theme/default/img/login_bg.jpg")
        no-repeat;
      background-size: 100%;
    }
  }
  .u-top-logo {
    width: 156px;
    height: 26px;
    position: absolute;
    left: 30px;
    top: 20px;
  }
  .u-center-logo {
    width: 430px;
    height: 44px;
    position: absolute;
    left: 25%;
    top: 50%;
    transform: translate(-50%);
  }
  .u-nav {
    width: 330px;
    height: 30px;
    position: absolute;
    top: 30px;
    right: 30px;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
  .u-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 320px;
    padding: 32px 50px;
    background: #ffffff;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 30px;
    z-index: 9999;
    .u-tt {
      font-size: 18px;
      font-weight: 500;
      line-height: 60px;
      // margin-bottom:38px;
      color: #333333;
      text-align: center;
    }
    .u-tab {
      display: flex;
      justify-content: space-around;
      line-height: 50px;
      margin-bottom: 24px;
      font-size: $fs-title-small;
      color: #333333;
      .u-sn {
        cursor: pointer;
        position: relative;
        &::after {
          width: 60px;
          height: 2px;
          content: "";
          display: none;
          background: white;
          position: absolute;
          bottom: 8px;
          left: 50%;
          transform: translate(-50%);
        }
        &[data-active] {
          color: #333333;
          text-decoration: none;
          &::after {
            display: block;
          }
        }
      }
    }
    .u-it {
      display: flex;
      position: relative;
      margin-bottom: 25px;
      background: transparent;
      border-radius: 4px;
      border: 1px solid rgba(62, 141, 255, 0.3);
      // height:40px;
      width: 100%;
      justify-content: space-between;
      position: relative;
      &:after {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        top: 10px;
        left: 10px;
      }
      &.u-account {
        &:after {
          background: url($lg-icon-account);
        }
      }
      &.u-password {
        &:after {
          background: url($lg-icon-password);
        }
      }
      .u-it-in {
        width: 310px;
        height: 39px;
        border-radius: 0 4px 4px 0;
        margin-left: 40px;
        background: transparent;
        color: #333333;
        outline: none;
        box-shadow: none;
        border: none;
      }
      .u-ver {
        margin-right: 10px;
        flex: 2;
        .u-it-in {
          width: 180px;
        }
      }
      .u-ver-btn {
        flex: 1;
      }
      .u-tips {
        position: absolute;
        top: 45px;
        left: 0;
        color: $fc-danger;
      }
    }
    .u-operating {
      display: flex;
      margin-bottom: 25px;
      justify-content: space-between;
      font-size: $fs-body;
      color: $fc-regular;
      .u-retrieve {
        color: $fc-info;
        cursor: pointer;
      }
    }
    .u-btn {
      width: 100%;
    }
  }
}
</style>

<style>
</style>
