import configDev from './dev'
import configProd from './prod'
import configDemo from './demo'
import configTest from './test'
const configDebug = configDev
/**
 *
 * @param {企业端（组织碳）}
 baseUrl+ /api/v3/yitan-organization-api/login/getAesKey
 * @param {saas端（组织碳）}
  http://36.138.93.142:8111/api/v3/yitan-system-api/login/getAesKey} env
 */
/* eslint-disable */
const getConfig = (env = process.env.VUE_APP_SECRET) => {
  /* eslint-disable */
  let config = {}
  switch (env) {
    case 'prod':
      config = configProd
      break
    case 'demo':
      config = configDemo
      break
    case 'test':
      config = configTest
      break
    default:
      config = configDebug
  }
  return config
}
export default getConfig
