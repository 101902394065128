const config = {
  port: 80,
  name: 'ZEUS',
  room: 'carbonebook.com', // 静态服务地址
  pathZeus: 'http://qas2.carbonebook.com', // 权限中心(未登录重定向到综管平台地址)
  TZJApi: 'https://dev.carbonebook.com/tantan-gw/api/v3/tantan-smartReduction-api',
  TZHApi: 'https://dev.carbonebook.com/tantan-gw/api/v3/tantan-zhihu-api',
  LiteApi: 'https://dev.carbonebook.com/tantan-gw/api/v3/tantan-liteCarbonebook-api',
  TShangApi: 'https://dev.carbonebook.com/tantan-gw/api/v3/tantan-business-api',
  MIDDLEAPi: 'https://dev.carbonebook.com/tantan-gw/api/v3/tantan-middle-api', // 当前系统对应后台地址
  serverApi: 'https://dev.carbonebook.com/tantan-gw/api/v3/tantan-liteCarbonebook-api', // 当前系统对应后台地址
  ftpUrl: 'https://media.carbonebook.com',
  secretKey: 'richeninfo'
}
export default config

