import LocalStorage from 'local-storage'

// import bank from './bank.json'
// import tenant from './tenant.json'
// import user from './user.json'
/*
* type 字段暂时弃用
* */
export default (type = '') => {
  // if (type === 'BANKUSER') return bank
  // if (type === 'TENANTUSER') return tenant
  // return user
  // if (type === 'BANKUSER') return tenant
  // return bank
  return (LocalStorage.get('navData') || {}).value
}
