
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script type="text/ecmascript-6">
import api from '@/api'
export default {
  data () {
    return {
      isLoading: true
    }
  },
  watch: {
    $route () {
      // this.tool.checkLogin(() => {
      //   if (this.$route.name !== 'login') this.$router.push('/login')
      // })
    }
  },
  created () {
    // if(!this.$store.state.user.decodeKey){
    //   alert(222)
    //   api.auth.getAesKey().then(res => {
    //     this.$store.commit('setUser', {
    //       decodeKey: res.data
    //     })
    //     this.isLoading=false
    //   })
    // }

    var path = '/home'
    // alert(path)
    if (this.$route.meta.auth) {
      this.tool.checkLogin(() => { // 没登录去登录 以后换成免登页面机制
        this.tool.clearLocalData()
        this.$store.commit('clearUser', {})
        this.$router.replace('/login?redirect=' + encodeURIComponent(path))
      })
    }

    api.auth.getAesKey().then(res => {
      this.$store.commit('setUser', {
        ...this.$store.state.user,
        decodeKey: res.data
      })
      this.isLoading = false
    })
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import "./assets/css/element-variables.scss";
@import "~font-awesome/css/font-awesome.min.css";
@import "./assets/css/global-variable";
@import "./assets/css/theme";
@import "./assets/css/index";
@import "./assets/font/icon-font/iconfont.css";

#app {
  width: 100%;
  height: 100%;
}
</style>
