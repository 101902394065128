// 测试环境
const config = {
  port: 80,
  name: 'ZEUS',
  room: '', // 静态服务地址
  pathZeus: 'http://36.138.93.142:9998/tantan', // 权限中心(未登录重定向到综管平台地址)
  TZJApi: 'http://36.138.93.142:8111/api/v3/tantan-smartReduction-api',
  TZHApi: 'http://36.138.93.142:8111/api/v3/tantan-zhihu-api',
  LiteApi: 'http://36.138.93.142:8111/api/v3/tantan-liteCarbonebook-api',
  TShangApi: 'http://36.138.93.142:8111/api/v3/tantan-business-api',
  MIDDLEAPi: 'http://36.138.93.142:8111/api/v3/tantan-middle-api',
  serverApi: 'http://36.138.93.142:8111/api/v3/tantan-liteCarbonebook-api/', // 当前系统对应后台地址
  ftpUrl: 'http://media.carbonebook.com',
  secretKey: 'richeninfo'
}
export default config
