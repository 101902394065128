import * as tslib_1 from "tslib";
// 重写el-button的click事件
// https://qiita.com/ryo2132/items/4d43209ea89ad1297426#computed
import ElButton from "element-ui/packages/button";
import { Component, Prop, Mixins, } from "vue-property-decorator";
let Mybutton = class Mybutton extends Mixins(ElButton) {
    constructor() {
        super(...arguments);
        this.timeId = null;
        // 判断是否是同一个按钮
        this.btnText = null;
    }
    handleClick(evt) {
        console.log(this.delay);
        if (!this.delay)
            return this.$emit("click", evt);
        if (this.timeId && this.btnText === evt.target.innerText)
            return;
        this.btnText = evt.target.innerText;
        this.$emit("click", evt);
        this.timeId = setTimeout(() => {
            this.timeId = null;
        }, 1000);
    }
};
tslib_1.__decorate([
    Prop({ default: true })
], Mybutton.prototype, "delay", void 0);
Mybutton = tslib_1.__decorate([
    Component
], Mybutton);
export default Mybutton;
