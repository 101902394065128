import VueCookie from 'vue-cookie'
import Vue from 'vue'
import getConfig from '@/config'

// 清除本地数据
const clearLocalData = () => {
  VueCookie.delete('userId')
  VueCookie.delete('userName')
  VueCookie.delete('client-token', { domain: getConfig().room })
  VueCookie.delete('phone')
  // VueCookie.delete('ability')
  Vue.local.remove('ability')
  VueCookie.delete('role')
  VueCookie.delete('orgId')
  VueCookie.delete('email')
  VueCookie.delete('activePath')
  VueCookie.delete('isDemoAccount')
  sessionStorage.removeItem('_sid')
}

export default clearLocalData
