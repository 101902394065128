<template>
  <div class="g-box">
    <div id="myCanvas-wrap" class="">
      <div class="home-bg"></div>
      <div id="myCanvas">
        <div class="home-box">
          <div class="home-logo-1"></div>
          <div class="home-logo-2"></div>
          <div class="home-light"></div>
          <div class="home-earth"></div>
          <div
            class="home-yyBtn"
            v-if="$store.state.user.userType == 3"
          >
            <div @click="toYYLink" class="home-yyBtn-info">碳探（CMS）标准版快速了解</div>
            <div @click="yyClick" class="home-yyBtn-btn">
              {{ $t("global.预约升级到标准版") }}
            </div>
          </div>
          <div class="home-top-header">
            <div class="header-right">
              <!--              <span class="right-item">政策法规</span>-->
              <!--              <span class="right-item">学习中心</span>-->
              <!--              <span class="right-item">减碳咨询</span>-->
              <span class="right-item lang" v-if="false">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <span class="lang-item" v-if="$i18n.locale == 'zh'">
                      <!-- <span class="lang-icon"
                        ><img :src="publicPath + '/lang/zh.png'"
                      /></span> -->
                      中文 <i class="el-icon-arrow-down el-icon--right"></i
                    ></span>
                    <span class="lang-item" v-if="$i18n.locale == 'en'">
                      <!-- <span class="lang-icon"
                        ><img :src="publicPath + '/lang/en.png'"
                      /></span> -->
                      English <i class="el-icon-arrow-down el-icon--right"></i
                    ></span>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      @click.native="toggleLang('zh')"
                      :disabled="$i18n.locale == 'zh'"
                    >
                      <!-- <span class="lang-icon"
                        ><img :src="publicPath + '/lang/zh.png'"
                      /></span> -->
                      中文</el-dropdown-item
                    >
                    <el-dropdown-item
                      @click.native="toggleLang('en')"
                      :disabled="$i18n.locale == 'en'"
                    >
                      <!-- <span class="lang-icon"
                        ><img :src="publicPath + '/lang/en.png'"
                      /></span> -->
                      English</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
              <el-dropdown
                v-if="$store.state.user.userName || $store.state.user.phone"
              >
                <span class="el-dropdown-link">
                  <span style="display: flex; align-items: center">
                    <span class="avator"><img :src="avator" alt="头像" /></span>
                    <span>{{
                      $store.state.user.userName || $store.state.user.phone
                    }}</span>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="$store.state.user.userType !== 3"
                    @click.native="handleChangePass()"
                    >{{ $t("global.修改密码") }}</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="handleLogout()">{{
                    $t("global.退出登录")
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <span class="right-item" v-else @click="handleGoLogin">{{
                $t("global.请登录")
              }}</span>
            </div>
          </div>
          <div class="home-logo"></div>
          <div class="home-tt"></div>
          <div class="grid-box">
            <div
              class="grid-box-row"
              v-for="(row, index) in menuList"
              :key="index"
            >
              <div
                class="grid-box-item"
                @click="handleGoPath(item, true)"
                v-for="(item, index2) in row"
                :key="index2"
                :class="{ disabled: item.isAuth === 0 }"
              >
                <div class="lock">
                  <img
                          :src="require('@/assets/img/lite/lock-img.png')"
                          alt=""
                  />
                </div>
                <div class="item-box">
                  <div
                    class="item-box-icon"
                    :class="item.iconName || item.icon"
                  ></div>
                  <div class="item-box-title">
                    {{ $t(`menu['${item.title}']`) }}
                  </div>
                </div>
              </div>
              <!--                            <div class="grid-box-item" @click="handleGoPath()">-->
              <!--                                <div class="item-box">-->
              <!--                                    <div class="item-box-icon icon2"></div>-->
              <!--                                    <div class="item-box-title">碳足迹</div>-->
              <!--                                </div>-->
              <!--                            </div>-->
              <!--                            <div class="grid-box-item" @click="handleGoPath()">-->
              <!--                                <div class="item-box">-->
              <!--                                    <div class="item-box-icon icon3"></div>-->
              <!--                                    <div class="item-box-title">碳商</div>-->
              <!--                                </div>-->
              <!--                            </div>-->
              <!--                            <div class="grid-box-item" @click="handleGoPath('/authority-management/user/user-list')">-->
              <!--                                <div class="item-box">-->
              <!--                                    <div class="item-box-icon icon4"></div>-->
              <!--                                    <div class="item-box-title">系统管理</div>-->
              <!--                                </div>-->
              <!--                            </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <my-dialog ref="myDialog" />
  </div>
</template>

<script>
import api from "@/api";
import tool from "@/tool";
import getConfig from "@/config";
import Vue from "vue";
import VueCookie from "vue-cookie";
import MyDialog from "./dialog";
export default Vue.extend({
  name: "home",
  directives: {},
  filters: {},
  components: {
    MyDialog,
  },
  props: {},
  data() {
    return {
      publicPath: getConfig().pathZeus,
      formInline: {
        page: {
          total: 0,
          pageSize: 10,
          currentPage: 1,
        },
      },
      tabActive: "account",
      ruleForm: {
        account: "",
        accountTips: "",
        password: "",
        passwordTips: "",
        rememberAccount: false,
        phone: "",
        phoneTips: "",
        verificationCode: "",
        verificationCodeState: false,
        countdown: 60,
        haloImg: "",
        logo: "",
        systemName: "",
      },
      filter: {},
      interval: {},
      homePath: "",
      menuList: [],
      avator: getConfig().pathZeus + "/avator.png",
    };
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {
    if (!VueCookie.get("client-token")) {
      this.tool.clearLocalData();
      this.$store.commit("clearUser", {});
    }
    sessionStorage.removeItem("_sid");
    api.menu
      .queryHomePageMenuList({
        channel: this.$store.state.user.channel,
      })
      .then((res) => {
        // 校验当前用户是否有这个权限
        // const tempHomeMenu = (res.data || []).map(item => ({ ...item, disabled: !userMenuData.includes(item.id) }))
        const tempHomeMenu = res.data;
        var rowList = [];
        var k = 0;
        for (var i = 0; i < tempHomeMenu.length; i++) {
          if (!rowList[k]) {
            rowList[k] = [];
          }
          rowList[k].push(tempHomeMenu[i]);
          if (rowList[k].length == 4 + k) {
            k++;
          }
        }
        this.menuList = rowList;
      })
      .catch((e) => {
        console.log("e", e);
      });
  },
  beforeMount() {},
  mounted() {
    this.resizeCanvas();

    window.addEventListener("resize", (_) => this.resizeCanvas(), false);
    if (
      this.$store.state.user.isFirstLogin === 1 &&
      this.$store.state.user.userType !== 3
    ) {
      this.$refs.myDialog.show(false);
    }
  },
  activated() {},
  deactivated() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    yyClick () {
      this.$confirm('尊敬的用户，感谢您使用碳探Lite版，您的升级请求已经发送到系统后台，我们的系统顾问将会尽快和您联系', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          api.lite.checkRegUser({}).then(check=>{
            console.log(check)
            if(!check.data.loginName){
              this.$router.push('/lite-fill-N')
            }else{
              api.auth.upgradeFormalVersion({}).then((res) => {
                console.log(res)
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: 'success'
                })
              })
            }
          })
        })
        .catch(() => {})
    },
    toYYLink(){
      window.open('http://www.carbonebook.com/index.php/zh/features')
    },
    init() {},
    resizeCanvas(scale) {
      var wrap = document.querySelector("#myCanvas-wrap");
      var s = document.querySelector("#myCanvas");

      var pw = 1440; // 设计图的宽
      var ph = 900; // 设计图的宽
      var tww = wrap.offsetWidth;

      var twh = wrap.offsetHeight;

      var scaleCenter = "0% 0%";

      var tw = 0;
      var th = 0;

      var wsc = 1; // 获取宽比例

      if ((pw / tww) * twh > ph) {
        if (tww / pw > 1) {
          console.log("超容器适配");
          scaleCenter = "50% 50%";
          wsc = tww / pw;
          th = Math.max(0, (twh - ph) * 0.5);
          tw = Math.max(0, (tww - pw) * 0.5);
        } else {
          console.log("宽适配");
          scaleCenter = "0% 0%";
          wsc = tww / pw;
          th =
            ph < twh
              ? Math.min(0, -(ph - twh) * 0.5)
              : Math.max(0, (twh - ph) * 0.5);
          tw = 0; // Math.min(0, (pw - tww) * 0.5);
        }
      } else if ((ph / twh) * tww >= pw) {
        if (twh / ph > 1) {
          console.log("超容器适配");
          scaleCenter = "50% 50%";
          wsc = twh / ph;
          th = Math.max(0, (twh - ph) * 0.5);
          tw = Math.max(0, (tww - pw) * 0.5);
        } else {
          console.log("高适配");
          scaleCenter = "50% 0%";
          wsc = twh / ph; // 获取宽比例
          th = Math.min(0, (ph - twh) * 0.5);
          tw =
            pw > tww
              ? Math.min(0, -(pw - tww) * 0.5)
              : Math.max(0, (tww - pw) * 0.5);
        }
      }

      // 先宽自适应 wscale
      s.style.transformOrigin = scaleCenter;
      s.style.webkitTransformOrigin = scaleCenter;
      s.style.transform =
        "translate3d(" + 0 + "px, 0px, 0px) scale(" + wsc + ")";
      s.style.webkitTransform =
        "translate3d(" + 0 + "px,0px, 0px) scale(" + wsc + ")";
      s.style.marginTop = th + "px";
      s.style.marginLeft = tw + "px";

      console.log("重置大小 ", wsc);
      this.$emit("resize", wsc);
    },
    handleGoLogin() {
      this.$router.push("/login");
    },
    handleLogout() {
      tool.clearLocalData();
      this.$store.commit("clearUser", {});
      api.auth.getAuthLogout({}).then((res) => {});
      this.$router.replace("/login");
    },
    handleChangePass() {
      this.$refs.myDialog.show();
    },
    handleGoPath(item, home) {
      // TODO 写法太乱 有问题
      if (!item.name == "liteFull") {
        if (this.$store.user.isFrist) {
          this.$router.push(111);
        } else {
          this.$router.push(2222);
        }
        return;
      }
      if (!item.path) {
        this.$message.error("页面路径未配置");
        return;
      }
      if (item.isAuth === 0) {
        if (this.$store.state.user.userType === 3) {
          this.$message.error(this.$t("global['碳探标准版专享']"));
        } else {
          this.$message.error(this.$t("global['暂无权限']"));
        }
        return;
      }
      var wList = [];
      if (wList.includes(item.name)) {
        window.open(getConfig().pathZeus + item.path, "_self");
      } else if (
        item.path.indexOf("http") > -1 ||
        item.path.indexOf("https") > -1
      ) {
        var toHome = home ? "&type=home" : "";
        window.open(
          item.path + "?lang=" + localStorage.getItem("locale") + toHome,
          "_self"
        );
      } else if (!VueCookie.get("client-token")) {
        window.open(
          getConfig().pathZeus +
            "/login?redirect=" +
            encodeURIComponent(item.path + "?sid=" + item.id)
        );
      } else {
        api.menu
          .authMenu({ id: item.id })
          .then((res) => {
            this.$router.push(item.path + "?sid=" + item.id);
          })
          .catch((_) => {
            if (this.$store.state.user.userType === 3) {
              this.$message.error(this.$t("global['碳探标准版专享']"));
            } else {
              this.$message.error(this.$t("global['暂无权限']"));
            }
          });
      }
    },
    toggleLang(lang) {
      if (lang === "zh") {
        localStorage.setItem("locale", "zh");
        this.$i18n.locale = localStorage.getItem("locale");
        // this.$message({
        //   message: '切换为中文！',
        //   type: 'success'
        // })
        window.location.reload();
      } else if (lang === "en") {
        localStorage.setItem("locale", "en");
        this.$i18n.locale = localStorage.getItem("locale");
        // this.$message({
        //   message: 'Switch to English!',
        //   type: 'success'
        // })
        window.location.reload();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  @import "./../../assets/css/global-variable";
  .g-box {
    overflow-x: hidden;
    height: 100%;
    background: #ffffff;
    width: 100%;
  }

  .home-top-header {
    position: absolute;
    top: 0;
    width: 100%;
    right: 0;
    left: 0;
    z-index: 99;
    height: 60px;
    .sub-logo-box {
      position: absolute;
      top: 25px;
      left: 165px;
      display: flex;
      align-items: center;
      height: 32px;
      .el-icon-close {
        color: #fff;
        font-size: 18px;
        font-weight: 700;
      }
      .sub-logo-img {
        margin-left: 15px;
        max-width: 90px;
        min-width: 32px;
        height: 32px;
      }
    }
    .header-name {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-size: 20px;
    }
    .header-right {
      right: 60px;
      top: 10px;
      position: absolute;
      height: 60px;
      display: flex;
      align-items: center;

      span.right-item {
        font-size: 14px;
        color: #ffffff;
        margin-left: 30px;
        margin-right: 30px;
        cursor: pointer;
      }
      span.user-box {
        cursor: pointer;
        color: #ffffff;
        font-size: 14px;
      }
      .el-dropdown-link {
        cursor: pointer;
      }
      .el-dropdown {
        color: #ffffff;
      }
    }
  }

  .home-box {
    width: 100%;
    position: relative;
    img {
      width: 100%;
    }
  }
  .home-yyBtn {
    position: absolute;
    right: 90px;
    top: 275px;
    font-size: 14px;
    line-height: 40px;
    display: flex;
    z-index: 999;
    &-info {
      cursor: pointer;
      color: #fff;
      margin-right: 20px;
    }
    &-info:hover {
      text-decoration: underline;
    }
    &-btn {
      height: 40px;
      width: 153px;
      color: $fc-theme;
      border-radius: 20px;
      text-align: center;
      background-color: #fff;
      cursor: pointer;
      box-shadow: inset 1px 1px 4px $fc-theme;
    }
  }

  .home-logo {
    background: url("~assets/img/logo.png") no-repeat;
    background-size: 100%;
    width: 91px;
    height: 32px;
    position: absolute;
    left: 60px;
    top: 25px;
  }
  // .home-tt {
  //   background: url("~assets/img/home/tt.png") no-repeat;
  //   background-size: 100%;
  //   width: 218px;
  //   height: 129px;
  //   position: absolute;
  //   left: 136px;
  //   top: 140px;
  // }
  /*.home-bg{*/
  /*    background: url('~assets/img/home/bg.jpg') no-repeat;*/
  /*    background-size: 100%;*/
  /*    height: 1080px;*/
  /*    opacity: 0.5;*/
  /*}*/

  #myCanvas-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    background: #070f3d url("~assets/img/home/home-bg.jpg") no-repeat 50% 0px;
    background-size: 1920px 1080px;
  }

  #myCanvas {
    display: block;
    position: relative;
    width: 1440px;
    height: 900px;
    position: absolute;

    .home-logo-1 {
      width: 128px;
      height: 83px;
      background: url("~assets/img/home/home-logo-1.png") no-repeat;
      background-size: cover;
      position: absolute;
      top: 80px;
      left: 740px;
    }
    .home-logo-2 {
      width: 462px;
      height: 79px;
      background: url("~assets/img/home/home-logo-2.png") no-repeat;
      position: absolute;
      top: 174px;
      left: 213px;
      background-size: cover;
    }

    .home-earth {
      width: 677px;
      height: 430px;
      background: url("~assets/img/home/home-earth.png") no-repeat;
      background-size: cover;
      position: absolute;
      top: 67px;
      left: 710px;
    }

    .home-light {
      width: 1440px;
      height: 900px;
      background: url("~assets/img/home/home-light.png") no-repeat;
      position: absolute;
      pointer-events: none;
      top: 0px;
      left: 0px;
      /*z-index: 10;*/
    }
  }

  .grid-box {
    width: 100%;
    position: absolute;
    top: 338px;
  }
  .item-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .item-box-icon {
      width: 86px;
      height: 86px;

      @for $i from 1 through 10 {
        &.icon#{$i} {
          background: url("~assets/img/home/icon#{$i}.png")
          center
          center
          no-repeat;
          background-size: auto 100%;
        }
      }
    }
    .item-box-title {
      text-align: center;
      margin-top: 20px;
      font-size: 18px;
      font-weight: bold;
    }
    .item-box-desc {
      text-align: center;
      margin-top: 10px;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .grid-box-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 24px;
  }
  .grid-box-item {
    position: relative;
    width: 214px;
    height: 214px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    cursor: pointer;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 200ms;
    &:hover {
      background: #006cff;
      color: #ffffff;
      transform: scale(1.05);
      .item-box-icon {
        width: 86px;
        height: 86px;

        @for $i from 1 through 10 {
          &.icon#{$i} {
            background: url("~assets/img/home/icon#{$i}-active.png") no-repeat;
            background-size: 100%;
          }
        }
      }
    }
    .lock {
      display: none;
    }
    &.disabled {
      color: #c5c5c5;
      .item-box-icon {
        filter: grayscale(100%);
      }
      .lock {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        display: block;
        height: 0;
        border-top: 27px solid #67a8ff;
        border-right: 27px solid #67a8ff;
        border-left: 27px solid transparent;
        border-bottom: 27px solid transparent;
        img {
          width: 24px;
          height: 24px;
          position: absolute;
          top: -24px;
          right: -24px;
        }
      }
      &:hover {
        background: #747474;
      }
    }
  }
  .lang {
    border: solid 1px #747474;
    padding: 4px 8px;
    border-radius: 2px;
  }
  .lang-icon {
    display: inline-block;
    width: 16px;
    height: 11px;
    img {
      width: 100%;
    }
  }
  .avator {
    width: 32px;
    height: 32px;
    background: #b1e7ff;
    display: inline-block;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
      display: inline-block;
    }
  }
</style>
