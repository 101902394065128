<template>
  <div class="g-box">
    <div id="myCanvas-wrap" class="">
      <div class="home-bg"></div>
      <div id="myCanvas">
          <div class="login-logo"></div>
          <div class="co2e-box"></div>
         <div class="g-login-box theme-light">
          <div class="u-box">
            <div>
                 <div class="u-login-title" style="margin-bottom:20px; font-weight: bold; font-size: 20px; text-align: center;">手机验证码登录</div>
                 <div class="u-it u-phone" style="margin-bottom: 24px">
                  <input
                    class="u-it-in"
                    v-model="ruleForm.phone"
                    placeholder="请输入手机号码"
                    @change="check('phone')"
                  />
                  <span class="u-tips">{{ ruleForm.phoneTips }}</span>
                </div>
                <div class="u-it u-password u-phoneCode">
                  <input
                    autocomplete="off"
                    class="u-it-in"
                    v-model="ruleForm.verificationCode"
                    placeholder="请输入系统登录验证码"
                    @keyup.enter="login"
                  />
                  <div
                    class="u-ver-btn"
                    :disabled="ruleForm.verificationCodeState"
                    @click="getVerificationCode"
                  >
                    {{ codeBtnText }}
                  </div>
                </div>
                <div style="height: 48px"></div>
            </div>
            <!-- <el-tabs v-model="tabActive">
              <el-tab-pane label="手机验证码登录" name="phone">
                <div class="u-it u-phone" style="margin-bottom: 24px">
                  <input
                    class="u-it-in"
                    v-model="ruleForm.phone"
                    placeholder="请输入手机号码"
                    @change="check('phone')"
                  />
                  <span class="u-tips">{{ ruleForm.phoneTips }}</span>
                </div>
                <div class="u-it u-password u-phoneCode">
                  <input
                    autocomplete="off"
                    class="u-it-in"
                    v-model="ruleForm.verificationCode"
                    placeholder="请输入系统登录验证码"
                    @keyup.enter="login"
                  />
                  <div
                    class="u-ver-btn"
                    :disabled="ruleForm.verificationCodeState"
                    @click="getVerificationCode"
                  >
                    {{ codeBtnText }}
                  </div>
                </div>
                <div style="height: 48px"></div>
              </el-tab-pane>
              <el-tab-pane label="邮箱密码登录" name="account">
                <div
                  class="u-it u-account"
                  style="margin-bottom: 24px; margin-top: -12px"
                >
                  <input
                    class="u-it-in"
                    v-model="ruleForm.account"
                    placeholder="请输入账号"
                    @change="check('account')"
                    @keyup.enter="login"
                  />
                  <span class="u-tips">{{ ruleForm.accountTips }}</span>
                </div>
                <div class="u-it u-password">
                  <input
                    class="u-it-in"
                    type="password"
                    v-model="ruleForm.password"
                    placeholder="请输入密码"
                    @change="check('password')"
                    @keyup.enter="login"
                  />
                  <span class="u-tips">{{ ruleForm.passwordTips }}</span>
                </div>
                <div class="u-it u-vcode-box">
                  <input
                    class="u-it-in"
                    type="text"
                    v-model="ruleForm.imgCode"
                    placeholder="请输入图形验证码"
                    @change="check('code')"
                    @keyup.enter="login"
                  />
                  <span class="u-vcode"
                    ><img :src="imgCode" alt="加载中" @click="getCodeImg()"
                  /></span>
                  <span class="u-tips">{{ ruleForm.codeTips }}</span>
                </div>
              </el-tab-pane>
            </el-tabs> -->
            <div class="u-operating">
              <div class="u-el u-remenber">
                <el-checkbox
                  v-if="tabActive === 'account'"
                  v-model="ruleForm.rememberAccount"
                  >记住密码</el-checkbox>
              </div>
            </div>
            <el-button
              class="u-btn"
              type="primary"
              @click="login"
              :loading="loading"
              >登 录</el-button>
            <div class="u-reg-text" @click="toReg">
                还不是碳探用户，快速注册了解企业碳排放，<span style="color: #0483EFFF">请点这里</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import api from './../../api'
import getConfig from './../../config'
import { Notification } from 'element-ui'
// import loadScene from './../../tool/canvas'
export default Vue.extend({
  name: 'home',
  components: {},
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      formInline: {
        page: {
          total: 0,
          pageSize: 10,
          currentPage: 1
        }
      },
      tabActive: 'phone',
      ruleForm: {
        account: '',
        accountTips: '',
        password: '',
        passwordTips: '',
        rememberAccount: false,
        rememberPhone: false,
        phone: '',
        phoneTips: '',
        verificationCode: '',
        verificationCodeState: false,
        countdown: 60,
        mdPw: '',
        haloImg: '',
        logo: '',
        systemName: '',
        imgCode: '',
        codeTips: ''
      },
      imgCode: '',
      imgKey: '',
      filter: {},
      interval: {},
      homePath: '',
      loading: false
    }
  },
  computed: {
    codeBtnText () {
      if (this.ruleForm.verificationCodeState) {
        return this.ruleForm.countdown
      } else {
        return '获取验证码'
      }
    }
  },
  watch: {},
  beforeCreate () { },
  created () {
    // console.log()
    if (this.$route.query.phone) {
      this.tabActive = 'phone'
    }
    this.init()
  },
  beforeMount () { },
  mounted () {
    this.resizeCanvas()

    window.addEventListener('resize', _ => this.resizeCanvas(), false)
  },
  activated () { },
  deactivated () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () {
    clearInterval(this.interval)
    // this.deleteGlobalResize('login')
  },
  destroyed () { },
  methods: {
    init () {
      this.ruleForm.rememberAccount =
        VueCookie.get('client-rememberAccount') === 'true'
      this.ruleForm.rememberPhone =
        VueCookie.get('client-rememberPhone') === 'true'
      if (this.ruleForm.rememberAccount) {
        this.ruleForm.account = VueCookie.get('client-account')
        this.ruleForm.password = '********'
      }
      if (this.ruleForm.rememberPhone) {
        this.ruleForm.phone = VueCookie.get('client-phone')
      }
      this.getCodeImg()
      // api.config.postQuerySystemConfig({}, {
      //   check401Error: false
      // }).then(res => {
      //   this.ruleForm.haloImg = res.data.infoImage
      //   this.ruleForm.logo = res.data.logo
      //   this.ruleForm.systemName = res.data.systemName
      // })
    },
    getCodeImg () {
      api.auth.getImgCode({

      }).then(res => {
        this.imgCode = 'data:image/png;base64,' + res.data.imgCode
        this.imgKey = res.data.imgKey
      })
    },
    toReg () {
      this.$router.push('/register')
    },
    resizeCanvas (scale) {
      var wrap = document.querySelector('#myCanvas-wrap')
      var s = document.querySelector('#myCanvas')

      var pw = 1440 // 设计图的宽
      var ph = 900 // 设计图的宽
      var tww = wrap.offsetWidth

      var twh = wrap.offsetHeight

      var scaleCenter = '0% 0%'

      var tw = 0
      var th = 0

      var wsc = 1 // 获取宽比例

      if (pw / tww * twh > ph) {
        if (tww / pw > 1) {
          console.log('超容器适配')
          scaleCenter = '50% 50%'
          wsc = tww / pw
          th = Math.max(0, (twh - ph) * 0.5)
          tw = Math.max(0, (tww - pw) * 0.5)
        } else {
          console.log('宽适配')
          scaleCenter = '0% 0%'
          wsc = tww / pw
          th = ph < twh ? Math.min(0, -(ph - twh) * 0.5) : Math.max(0, (twh - ph) * 0.5)
          tw = 0 // Math.min(0, (pw - tww) * 0.5);
        }
      } else if (ph / twh * tww >= pw) {
        if (twh / ph > 1) {
          console.log('超容器适配')
          scaleCenter = '50% 50%'
          wsc = twh / ph
          th = Math.max(0, (twh - ph) * 0.5)
          tw = Math.max(0, (tww - pw) * 0.5)
        } else {
          console.log('高适配')
          scaleCenter = '50% 0%'
          wsc = twh / ph // 获取宽比例
          th = Math.min(0, (ph - twh) * 0.5)
          tw = pw > tww ? Math.min(0, -(pw - tww) * 0.5) : Math.max(0, (tww - pw) * 0.5)
        }
      }

      // 先宽自适应 wscale
      s.style.transformOrigin = scaleCenter
      s.style.webkitTransformOrigin = scaleCenter
      s.style.transform = 'translate3d(' + 0 + 'px, 0px, 0px) scale(' + wsc + ')'
      s.style.webkitTransform = 'translate3d(' + 0 + 'px,0px, 0px) scale(' + wsc + ')'
      s.style.marginTop = th + 'px'
      s.style.marginLeft = tw + 'px'

      console.log('重置大小 ', wsc)
      this.$emit('resize', wsc)
    },
    clearCache () {
      VueCookie.delete('userId')
      VueCookie.delete('email')
      // VueCookie.delete('userName')
      VueCookie.delete('phone')
      VueCookie.delete('orgId') // 组织id
      // VueCookie.set('orgName', res.data.user.orgName || '') // 组织名称
      VueCookie.delete('parkId') // 园区id
      // VueCookie.set('pageUrl', res.data.pageUrl || '')
      VueCookie.delete('role')
      // VueCookie.set('scope', (res.data.scope || []).join(','))
      VueCookie.delete('client-rememberAccount')
      VueCookie.delete('client-rememberPhone')
      VueCookie.delete('client-token')
      VueCookie.delete('client-account')
      VueCookie.delete('client-password')
      VueCookie.delete('client-phone')

      this.$store.commit('clearUser', {})

      Notification({
        title: '成功',
        message: '清理缓存成功 请重新尝试',
        type: 'success'
      })
    },
    login () {
      this.check()
      if (this.tabActive === 'account') {
        if (
          this.format(this.ruleForm.account) === ''
        ) {
          return
        }
        var reg = new RegExp(/^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/)
        if (!reg.test(this.ruleForm.account)) {
          this.ruleForm.accountTips = '账号必须是邮箱格式'
          return
        }
        if (this.format(this.ruleForm.password) === '') {
          return
        }
        if (this.format(this.ruleForm.imgCode) === '') {
          return
        }
        this.loading = true
        api.auth
          .postAuthSalt({
            email: this.ruleForm.account
          })
          .then((rep) => {
            api.auth.getAesKey().then(res => {
              this.$store.commit('setUser', {
                ...this.$store.state.user,
                decodeKey: res.data
              })
            }).then(_ => {
              this.loading = false
              const user = {
                type: 2,
                loginName: this.ruleForm.account,
                imgCode: this.ruleForm.imgCode,
                imgKey: this.imgKey,
                password:
                  this.ruleForm.password === '********'
                    ? VueCookie.get('client-password')
                    : this.ruleForm.password,
                publicKey: rep.data
                // password: md5((this.ruleForm.password === '********' ? VueCookie.get('password') : this.mdPw) + rep.data)
              }
              api.auth.postAuthLogin(user).then((res) => {
                this.afterLogin(res.data)
              }).catch(_ => {
                this.loading = false
              })
            })
          })
      } else {
        if (
          this.format(this.ruleForm.phone) === '' ||
          this.format(this.ruleForm.phone).length !== 11 ||
          isNaN(this.format(this.ruleForm.phone))
        ) {
          return
        }
        if (!this.ruleForm.verificationCode) return this.$message.warning(this.$t('Login.phoneCodeErrMsg'))
        this.loading = true
        api.auth
          .postAuthPhoneLogin({
            body: {
              phone: this.ruleForm.phone,
              phoneCode: this.ruleForm.verificationCode,
              userType: 3
            }
          })
          .then((res) => {
            this.afterLogin(res.data)
          }).catch(_ => {
            this.loading = false
          })
      }
    },
    afterLogin (data) {
      if (this.ruleForm.rememberAccount) {
        const account = this.ruleForm.account
        const password =
          this.ruleForm.password === '********'
            ? VueCookie.get('client-password')
            : this.ruleForm.password
        VueCookie.set('client-account', account || '', 1000 * 60 * 60 * 24 * 7)
        VueCookie.set('client-password', password || '', 1000 * 60 * 60 * 24 * 7)
      } else {
        VueCookie.delete('client-account')
        VueCookie.delete('client-password')
      }
      VueCookie.set('userId', data.id || '')
      VueCookie.set('email', data.loginName || '')

      // VueCookie.set('client-token2', data.loginName || '', { expires: 7, domain: 'carbonebook.com' })
      VueCookie.set('client-token', data.token || '', { expires: 7, domain: getConfig().room })
      VueCookie.set('phone', data.phone || '')
      if (this.ruleForm.rememberAccount) {
        VueCookie.set('client-rememberAccount', this.ruleForm.rememberAccount)
        VueCookie.set('client-account', data.loginName)
      } else {
        VueCookie.delete('client-account')
        VueCookie.delete('client-rememberAccount')
      }
      if (this.ruleForm.rememberPhone) {
        VueCookie.set('client-rememberPhone', this.ruleForm.rememberPhone)
        VueCookie.set('client-phone', data.phone)
      } else {
        VueCookie.delete('client-phone')
        VueCookie.delete('client-rememberPhone')
      }
      VueCookie.set('orgId', data.orgId || '') // 组织id
      // VueCookie.set('orgName', res.data.user.orgName || '') // 组织名称
      // VueCookie.set('parkId', res.data.user.parkId || '') // 园区id
      Vue.local.set('ability', (data.sysenum || []).join(','), 1000 * 60 * 60 * 24 * 31)
      // VueCookie.set('pageUrl', res.data.user.pageUrl || '')
      VueCookie.set('role', data.userRole || 'TENANTUSER')
      // // VueCookie.set('scope', (res.data.user.scope || ['ZEUS']).join(','))
      // // VueCookie.delete('showMenu', '')
      // VueCookie.delete('isDemoAccount')
      this.$store.commit('setUser', data)
      //
      // let routerData = []
      // if (data.userRole === 'BANKUSER') {
      //   routerData = bank
      // } else if (data.userRole === 'TENANTUSER' || data.userRole === 'USER') {
      //   routerData = tenant
      // }
      // this.$router.addRoutes(routerData)
      // this.$router.push(homePath || '/desk')
      // this.$router.replace('/desk')
      // this.getNavData(() => {
      //   this.loading = false
      //   var homePath = this.$route.query.redirect ? decodeURIComponent(this.$route.query.redirect) : '/home'
      //
      //   location.href = getConfig().pathZeus + homePath
      // })
      this.loading = false
      var homePath = this.$route.query.redirect ? decodeURIComponent(this.$route.query.redirect) : '/home'

      location.href = getConfig().pathZeus + homePath
    },
    findTreeData (tree = []) {
      return tree.map((item) => {
        if (
          !this.homePath &&
          item.component &&
          !item.path.includes(':') &&
          item.path
        ) { this.homePath = item.path }
        return {
          label: item.title,
          value: item.id,
          name: item.name,
          children: this.findTreeData(item.children)
        }
      })
    },
    getNavData (callback = () => { }) {
      api.config
        .postUserMenu({
          roleId: this.tool.getRoleId(VueCookie.get('role')),
          type: '1'
        })
        .then((res) => {
          Vue.local.set('homeNavData', res.data || [], 1000 * 60 * 60 * 24 * 31)
          callback()
        })
    },
    check (key = '') {
      if (this.tabActive === 'account') {
        if (key === 'account' || !key) this.ruleForm.accountTips = ''
        if (key === 'password' || !key) this.ruleForm.passwordTips = ''
        if (key === 'code' || !key) this.ruleForm.codeTips = ''
        if (
          this.format(this.ruleForm.account) === '' &&
          (key === 'account' || !key)
        ) {
          this.ruleForm.accountTips = '账号不能为空'
        }
        var reg = new RegExp(/^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/)
        if (key === 'account' && !reg.test(this.ruleForm.account)) {
          this.ruleForm.accountTips = '账号必须是邮箱格式'
        }
        if (
          this.format(this.ruleForm.password) === '' &&
          (key === 'password' || !key)
        ) {
          this.ruleForm.passwordTips = '密码不能为空'
        }
        if (
          this.format(this.ruleForm.imgCode) === '' &&
          (key === 'code' || !key)
        ) {
          this.ruleForm.codeTips = '验证码不能为空'
        }
      } else {
        this.ruleForm.phoneTips = ''
        if (
          this.format(this.ruleForm.phone) === '' &&
          (key === 'phone' || !key)
        ) {
          this.ruleForm.phoneTips = '手机号码不能为空'
        }
        if (
          (this.format(this.ruleForm.phone).length !== 11 ||
            isNaN(this.format(this.ruleForm.phone))) &&
          (key === 'phone' || !key)
        ) {
          this.ruleForm.phoneTips = '手机号码错误'
        }
      }
    },
    format (str) {
      return str.replace(/(^\s*)|(\s*$)/g, '')
    },
    // 过滤用户路由
    getRouter (router, code, ability = '') {
      const nameList = (ability || '')
        .split(',')
        .filter((item) => {
          return item.indexOf(code) === 0
        })
        .map((item) => {
          return item.substring(code.length + 1)
        })

      const filterTree = (list, nameList) => {
        return list
          .filter((item) => {
            return nameList.indexOf(item.name) !== -1 || !item.name
          })
          .map((item) => {
            return {
              ...item,
              children: item.children ? filterTree(item.children, nameList) : []
            }
          })
      }
      return filterTree(router, nameList)
    },
    // 获取验证码
    getVerificationCode () {
      if (this.ruleForm.verificationCodeState) return
      this.check()
      if (
        this.format(this.ruleForm.phone) === '' ||
        this.format(this.ruleForm.phone).length !== 11 ||
        isNaN(this.format(this.ruleForm.phone))
      ) {
        return
      }
      api.auth.getAuthPassCode({
        phone: this.ruleForm.phone
      }).then(res => {
        this.$message.success(this.$t('Login.sendOk'))
        clearInterval(this.interval)
        this.ruleForm.countdown = 60
        this.ruleForm.verificationCodeState = true
        this.interval = setInterval(() => {
          if (this.ruleForm.countdown) {
            this.ruleForm.countdown--
          } else {
            this.ruleForm.countdown = 60
            this.ruleForm.verificationCodeState = false
          }
        }, 1000)
      })
    }
  }
})
</script>
<style lang="scss" scoped>
    @import "./../../assets/css/global-variable";
    $lg-bg: "../../assets/img/login/lg-bg.png";
    $lg-icon-account: "../../assets/img/login/new-icon-account.png";
    $lg-icon-password: "../../assets/img/login/new-icon-password.png";
    $lg-icon-phone: "../../assets/img/login/new-icon-phone.png";
    .u-reg-text {
        margin-top: 19px;
        font-size: 16px;
        font-family: PingFangSC-Regular-, PingFangSC-Regular;
        font-weight: normal;
        color: #000000a6;
        line-height: 24px;
        cursor: pointer;
        user-select: none;
    }
    input::placeholder {
        font-size: 16px;
        line-height: 40px;
    }
    #myCanvas-wrap {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
        background: linear-gradient(-45deg, #202f69, #1b1c4c);
    }

    .home-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
        background: url("~assets/img/login/login-repeat.png") repeat;
    }
    #myCanvas {
        display: block;
        position: relative;
        width: 1440px;
        height: 900px;
        position: absolute;
        background-size: 1440px;
        .login-logo {
            position: absolute;
            top: 55px;
            left: 0px;
            width: 129px;
            height: 46px;
            background: url("~assets/img/login/login-logo.png") no-repeat;
            background-size: cover;
        }

        .co2e-box {
            position: absolute;
            top: 155px;
            left: 80px;
            width: 536px;
            height: 584px;
            background: url("~assets/img/login/co2e.png") no-repeat;
            background-size: cover;
        }
    }

    .g-login-box {
        * {
            box-sizing: border-box;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 1440px;
        height: 900px;
        .c {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            .mask {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                z-index: 30;
            }
            /*.bg {*/
            /*  width: 100%;*/
            /*  height: 100%;*/
            /*  position: absolute;*/
            /*  top: 0;*/
            /*    center/ 100% 100%;*/
            /*}*/
            .header {
                width: 100%;
                max-width: 1920px;
                height: 80px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                background: url($publicPath + "/bg/header.png") no-repeat;
                background-size: 100%;
                z-index: 99;
            }
            .header-title {
                text-align: center;
                line-height: 50px;
                font-size: 24px;
            }
        }
        .u-top-logo {
            width: 156px;
            height: 26px;
            position: absolute;
            left: 30px;
            top: 20px;
        }
        .u-center-logo {
            width: 430px;
            height: 44px;
            position: absolute;
            left: 25%;
            top: 50%;
            transform: translate(-50%);
        }
        .u-nav {
            width: 330px;
            height: 30px;
            position: absolute;
            top: 30px;
            right: 30px;
            color: #333333;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
        }

        .u-vcode {
            cursor: pointer;
            display: inline-block;
            width: 90px;
            height: 40px;
            background: #d8ecfe;
            border-radius: 4px;
            overflow: hidden;
            right: -100px;
            line-height: 40px;
            position: absolute;
            img {
                width: 130px;
                height: 40px;
            }
        }
        .u-box {
            box-sizing: border-box;
            position: absolute;
            left: 60%;
            top: 50%;
            padding: 30px 40px 40px 40px;
            width: 480px;
            min-height: 410px;
            background: #ffffff;
            box-shadow: 0px 10px 30px 1px rgba(0, 0, 0, 0.03);
            border-radius: 16px 16px 16px 16px;
            box-sizing: border-box;
            z-index: 99;
            transform: translateY(-50%);
            /deep/.el-tabs {
                .el-tabs__content {
                    padding-top: 30px;
                }
                .el-tabs__nav-wrap::after {
                    display: none;
                }
                .el-tabs__item {
                    font-size: 20px;
                    font-family: PingFangSC-Medium-, PingFangSC-Medium;
                    font-weight: 400;
                    color: #000000 !important;
                    &.is-active {
                        color: #0483ef !important;
                    }
                }
            }
            /deep/.el-radio-group {
                padding-left: 12px;
                margin-bottom: 24px;
            }
            .u-it {
                position: relative;
                margin-bottom: 20px;
                height: 40px;
                line-height: 40px;
                background: #ffffff;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #d9d9d9;
                input {
                    font-size: 18px;
                }
                &:after {
                    content: "";
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
                &.u-account {
                    &:after {
                        background-image: url($lg-icon-account);
                    }
                }

                &.u-vcode-box {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 1px;
                    width: 200px;
                    .u-it-in {
                        margin-left: 12px;
                    }
                }
                &.u-password {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 1px;
                    &.u-code {
                        margin-bottom: 39px;
                    }
                    &:after {
                        background-image: url($lg-icon-password);
                    }
                    &.u-phoneCode {
                        margin-top: 43px;
                    }
                    .u-ver-btn {
                        position: relative;
                        &[disabled="disabled"] {
                            opacity: 0.8;
                            background-color: #ffffff;
                            height: 32px;
                            color: #0483efff;
                            height: 32px;
                        }
                        &::before {
                            top: 7px;
                            position: absolute;
                            left: 0px;
                            content: "";
                            height: 25px;
                            width: 1px;
                            background-color: #d9d9d9;
                        }
                        font-size: 16px;
                        user-select: none;
                        height: 40px;
                        font-size: 16px;
                        color: #0483efff;
                        text-align: center;
                        width: 111px;
                        line-height: 40px;
                        /* background: #ffffff; */
                        cursor: pointer;
                    }
                }
                &.u-phone {
                    &:after {
                        background-image: url($lg-icon-phone);
                    }
                }
                .u-it-in {
                    width: calc(100% - 36px);
                    flex: 1;
                    text-indent: 8px;
                    height: 100%;
                    margin-left: 36px;
                    background-color: transparent;
                    outline: none;
                    box-shadow: none;
                    border: none;
                }
                input.u-it-in::-webkit-input-placeholder {
                    color: rgba(0, 0, 0, 0.25);
                }
                .u-ver {
                    margin-right: 10px;
                    flex: 2;
                    .u-it-in {
                        width: 180px;
                    }
                }
                .u-tips {
                    position: absolute;
                    top: 30px;
                    left: 0;
                    color: $fc-danger;
                }
            }
            .u-remenber {
                .el-checkbox {
                    color: #333333;
                }
            }
            .u-operating {
                margin-left: 12px;
                display: flex;
                margin-bottom: 20px;
                justify-content: space-between;
                font-size: $fs-body;
                color: #333333;
                .u-retrieve {
                    cursor: pointer;
                    color: #0483efff;
                }
            }
            .u-btn {
                width: 100%;
            }
        }
    }
</style>
