import ajax from '@/api/ajax';
import getConfig from '@/config';
import Base from './base';
class Factor extends Base {
    save(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/factor/saveFactor',
            method: 'post',
            code: 'factor-add'
        });
    }
    remove(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/factor/deleteFactorById',
            method: 'post',
            code: 'factor-remove'
        });
    }
    update(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/factor/updateFactor',
            method: 'post',
            code: 'factor-edit'
        });
    }
    queryPage(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/factor/queryFactorPage',
            method: 'post',
            code: 'factor-list'
        });
    }
    findById(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/factor/findFactorById',
            method: 'post',
            code: 'factor-view'
        });
    }
    queryList(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/factor/queryFactorList',
            method: 'post',
            code: 'factor-list'
        });
    }
    // 自测值停用或启用
    updateSelfTestStatusById(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/factor/updateSelfTestStatusById',
            method: 'post',
            code: 'factor-selfTest-disable'
        });
    }
    // 数据状态停用或启用
    updateStatusById(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/factor/updateStatusById',
            method: 'post',
            code: 'factor-updateStatus-list'
        });
    }
}
export default new Factor();
