
// const baseUrl = 'http://q3p4sj.natappfree.cc'
// const baseUrl = 'http://rrvzz3.natappfree.cc'
// const baseUrl = 'http://121.36.249.134:8111'
const baseUrl = 'http://123.60.75.157:8111'
// const baseUrl = 'http://tantan.5gzvip.91tunnel.com'
// const baseUrl = 'http://2zfe6m.natappfree.cc'

const config = {
  port: 8010,
  name: 'ZEUS',
  room: '', // 静态服务地址
  pathZeus: 'http://localhost:8010', // 权限中心(未登录重定向到综管平台地址)
  TZHApi: baseUrl + '/api/v3/tantan-zhihu-api',
  TShangApi: baseUrl + '/api/v3/tantan-business-api',
  serverApi: baseUrl + '/api/v3/tantan-liteCarbonebook-api', // demo版对应地址
  TZJApi: baseUrl + '/api/v3/tantan-smartReduction-api',
  LiteApi: baseUrl + '/api/v3/tantan-liteCarbonebook-api',
  MIDDLEAPi: baseUrl + '/api/v3/tantan-middle-api',
  uploadUrl: baseUrl + '/api/v3/yitan-system-api/upload/uploadFile',
  ftpUrl: 'http://media.carbonebook.com',
  secretKey: 'richeninfo'
}
export default config
