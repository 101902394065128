import ajax from '@/api/ajax';
import getConfig from '@/config';
export default {
    // 获取用户
    queryUserList(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/user/queryUserList',
            method: 'post'
        });
    },
    // 分页查询用户
    queryUserPage(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/user/queryUserPage',
            method: 'post'
        });
    }
};
