import ajax from '@/api/ajax';
import getConfig from '@/config';
export default {
    // 分页
    queryList(data) {
        return ajax(data, {
            url: getConfig().TZHApi + '/carbonVideo/queryCarbonVideoPage',
            method: 'post'
        });
    },
    // 根据详情id获取视频流
    queryFile(data) {
        // return fileInstanceAjax(getConfig().TZHApi + '/carbonVideo/uploadVideoStream', {
        //   id: data.id
        // })
        return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            //配置请求方式、请求地址以及是否同步
            // xhr.open('GET', 'http://jiuwenlong-bucket.oss-cn-shanghai.aliyuncs.com/videoOutput/ceshi222.m3u8', true);
            xhr.open('GET', getConfig().TZHApi + '/carbonVideo/uploadVideoStream?id=' + data.id, true);
            xhr.responseType = "blob";
            // xhr.withCredentials = true;
            //设置请求结果类型为 <br> blob xhr.responseType = 'blob';
            //请求成功回调函数
            xhr.onload = function (e) {
                // if (this.status == 200) {
                //   let blob = this.response;
                //   console.log(URL.createObjectURL(blob))
                resolve(xhr.response);
                // }
            };
            xhr.send();
        });
    },
    findById(data) {
        return ajax(data, {
            url: getConfig().TZHApi + '/carbonVideo/findCarbonVideoById',
            method: 'post'
        });
    },
    queryCarbonInformationList(data) {
        return ajax(data, {
            url: getConfig().TZHApi + '/carbonInformation/queryCarbonInformationList',
            method: 'post'
        });
    },
    findCarbonInformationById(data) {
        return ajax(data, {
            url: getConfig().TZHApi + '/carbonInformation/findCarbonInformationById',
            method: 'post'
        });
    },
};
