<template>
  <div v-if="dialogFormVisible2">
    <el-dialog
      :title="titleTable"
      :close-on-press-escape="false"
      :visible.sync="dialogFormVisible"
      :width="'680px'"
      @close="close"
      :close-on-click-modal="false"
      :show-close="showClone"
    >
      <el-form
        :model="deviceInfoForm"
        :rules="rules"
        ref="form"
        :label-width="'150px'"
        label-position="right"
        :class="{ 'my-form-readonly': isModelInfo }"
        size="large"
      >
        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item
              prop="oldPassword"
              :label="$t('global.旧密码') + '：'"
            >
              <el-input
                size="small"
                v-model="deviceInfoForm.oldPassword"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item prop="password" :label="$t('global.新密码') + '：'">
              <el-input
                size="small"
                v-model="deviceInfoForm.password"
                type="password"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item
              prop="confirmPwd"
              :label="$t('global.新密码确认') + '：'"
            >
              <el-input
                size="small"
                v-model="deviceInfoForm.confirmPwd"
                type="password"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="code" :label="$t('global.手机验证码') + '：'">
              <el-input
                size="small"
                v-model="deviceInfoForm.code"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <span style="margin-left: 0px">
              <el-button
                style="margin-top: 5px"
                v-if="!ruleForm.verificationCodeState"
                type="primary"
                size="small"
                @click="getVerificationCode"
              >
                {{ $t("global.获取验证码") }}
              </el-button>
              <el-button
                style="margin-top: 5px"
                v-else
                type="default"
                size="small"
              >
                <span>{{ ruleForm.countdown }} {{ $t("global.秒后发送") }} </span>
              </el-button>
            </span>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="isModelInfo">
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="close()"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
        <div v-else>
          <el-button @click="close" size="small" v-if="showClone">{{
            $t("global.cancel")
          }}</el-button>
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="submitForm('form')"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import md5 from 'js-md5'
import base from '@/templates/add'
import { treearr } from '@/tool'
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data () {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.请输入')))
      } else if (value !== this.deviceInfoForm.password) {
        callback(new Error(this.$t('global.两次输入密码不一致')))
      } else {
        callback()
      }
    }

    return {
      titleTable: this.$t('global.修改密码'),
      dialogFormVisible2: false,
      dialogFormVisible: false,
      posting: false,
      isModelEdit: false,
      isModelAdd: false,
      isModelInfo: false,
      deviceInfoForm: {
        oldPassword: '',
        password: '',
        confirmPwd: '',
        code: ''
      },
      ruleForm: {
        verificationCodeState: '',
        countdown: 60
      },
      rules: {
        oldPassword: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        password: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur', 'change'] },
          { pattern: /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/, message: this.$t('global.formatErr'), trigger: 'blur' },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        confirmPwd: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur', 'change'] },
          { validator: validatePass2, trigger: 'blur' },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        code: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ]
      },
      orgListTree: [],
      roleList: [],
      copyPassword: '',
      refreshOrgLoading: false,
      showClone: true
    }
  },
  created () {
    api.auth.getAuthRoleAllRole().then(res => {
      this.roleList = res.data
    })
  },
  methods: {
    show (flag = true) {
      this.showClone = flag
      this.dialogFormVisible2 = true
      this.$nextTick((_) => {
        this.dialogFormVisible = true
      })
    },
    close () {
      this.isModelEdit = false
      this.isModelAdd = false
      this.isModelInfo = false
      this.dialogFormVisible = false
      this.resetForm()
      this.$nextTick((_) => {
        this.dialogFormVisible2 = false
      })
    },
    add () {
      this.titleTable = this.$t('add.新增用户')
      this.isModelAdd = true
      this.show()
    },
    edit (data) {
      this.titleTable = this.$t('add.编辑用户')
      this.isModelEdit = true
      this.deviceInfoForm = { ...data, orgId: data.orgId }
      // this.copyPassword = this.deviceInfoForm.password
      this.show()
    },
    view (data) {
      this.titleTable = this.$t('add.用户详情')
      this.isModelInfo = true
      this.deviceInfoForm = { ...data }
      this.show()
    },
    // 重制
    resetForm () {
      this.deviceInfoForm = {}
      this.$refs.form.resetFields()
    },
    getVerificationCode () {
      if (this.ruleForm.verificationCodeState) return
      api.auth.sendUpdatePasswordVerificationCode({
        phone: this.$store.state.user.phone
      }).then(res => {
        this.$message.success(res.msg)
        clearInterval(this.interval)
        this.ruleForm.countdown = 60
        this.ruleForm.verificationCodeState = true
        this.interval = setInterval(() => {
          if (this.ruleForm.countdown) {
            this.ruleForm.countdown--
          } else {
            this.ruleForm.countdown = 60
            this.ruleForm.verificationCodeState = false
          }
        }, 1000)
      })
    },
    // 提交
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.isModelAdd) {
            // if (this.deviceInfoForm.password !== this.deviceInfoForm.confirmPwd) {
            //   return this.$message.warning('密码不一致')
            // }
          }
          this.posting = true
          // password: md5((this.ruleForm.password === '********' ? VueCookie.get('password') : this.mdPw) + rep.data)

          const query = {

            oldPassword: md5(this.deviceInfoForm.oldPassword),
            password: md5(this.deviceInfoForm.password),
            code: this.deviceInfoForm.code,
            id: this.$store.state.user.id
          }

          api.auth.updatePassWordById(query).then(res => {
            this.posting = false
            this.$message.success(this.$t('global.密码修改成功'))
            this.close()
            this.$store.commit('setUser', {
              ...this.$store.state.user,
              isFirstLogin: 0
            })
          }).catch(err => {
            this.posting = false
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.orgSelect {
  /deep/.el-form-item__content {
    display: flex;
    span {
      margin-left: 5px;
      white-space: nowrap;
      color: #0aa665;
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
