import ajax from '@/api/ajax';
import getConfig from '@/config';
export default {
    queryDeviceList(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/device/queryDeviceList',
            method: 'post'
        });
    },
    queryDeviceStatistics(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/device/queryDeviceStatistics',
            method: 'post'
        });
    }
};
