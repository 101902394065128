import ajax from '@/api/ajax';
import getConfig from '@/config';
class carbonSmartReduction {
    queryEmissionReductionList(data) {
        return ajax(data, {
            url: getConfig().TZJApi + '/carbonSmartReduction/queryEmissionReductionList',
            method: 'post'
        });
    }
    sendMail(data) {
        return ajax(data, {
            url: getConfig().TZJApi + '/carbonSmartReduction/sendMail',
            method: 'post'
        });
    }
    queryProvinceList(data) {
        return ajax(data, {
            url: getConfig().TZJApi + '/carbonSmartReduction/queryProvinceList',
            method: 'post'
        });
    }
    saveRecommendedProject(data) {
        return ajax(data, {
            url: getConfig().TZJApi + '/carbonSmartReduction/saveRecommendedProject',
            method: 'post'
        });
    }
}
export default new carbonSmartReduction();
