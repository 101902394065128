import ajax from '@/api/ajax';
import getConfig from '@/config';
export default {
    // 查询首页菜单
    queryHomePageMenuList(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/menu/queryHomePageMenuList',
            method: 'post',
            code: 'option-type'
        });
    },
    // 认证当前用户是否有菜单权限
    authMenu(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/menu/querySecondMenuByParentId',
            method: 'post'
        });
    },
    // 认证当前用户是否有菜单权限
    querySecondMenuByParentId(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/menu/querySecondMenuByParentId',
            method: 'post'
        });
    },
    // 修改或保存资源
    postMenuSaveOrUpdate(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/menu/saveOrUpdate',
            method: 'post'
        });
    },
    // 删除资源
    postMenuDelete(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/menu/delete',
            method: 'post'
        });
    },
    // 资源详情
    postMenuFindById(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/menu/findById',
            method: 'post'
        });
    }
};
