import ajax from '@/api/ajax';
import getConfig from '@/config';
export default {
    // 根据市场id查询区域
    findRegionBymarketId(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/trade/findRegionBymarketId',
            method: 'post',
        });
    },
    // 根据市场id 区域id 产品id 获取图表数据
    queryChatList(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/trade/queryChatList',
            method: 'post',
        });
    },
    // 获取市场信息表格
    queryMarketInfoPage(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/trade/queryMarketInfoList',
            method: 'post',
        });
    },
    // 获取我的发布
    queryMyMarketInfoPage(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/trade/queryMyMarketInfoPage',
            method: 'post',
        });
    },
    // 新增我的发布
    saveMarketInfo(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/trade/saveMarketInfo',
            method: 'post',
        });
    },
    // 删除我的发布
    deleteMarketInfoById(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/trade/deleteMarketInfoById',
            method: 'post',
        });
    },
    // 新增碳资产持仓
    saveAssetsInfo(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/assetsinfo/saveAssetsInfo',
            method: 'post',
        });
    },
    // 修改碳资产持仓
    updateAssetsInfo(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/assetsinfo/updateAssetsInfo',
            method: 'post',
        });
    },
    // 获取碳资产持仓
    queryMyAssetsInfoPage(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/assetsinfo/queryMyAssetsInfoPage',
            method: 'post',
        });
    },
    // 出售
    sellAssetsInfoById(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/assetsinfo/sellAssetsInfoById',
            method: 'post',
        });
    },
    // 注销
    logoffAssetsInfoById(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/assetsinfo/logoffAssetsInfoById',
            method: 'post',
        });
    },
    // 详情
    findAssetsInfoById(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/assetsinfo/findAssetsInfoById',
            method: 'post',
        });
    },
    // 删除
    deleteAssetsInfoById(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/assetsinfo/deleteAssetsInfoById',
            method: 'post',
        });
    },
    // 我的资产
    queryMyAssetsInfoList(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/assetsinfo/queryMyAssetsInfoList',
            method: 'post',
        });
    },
    // 获取碳资产历史
    queryMyhistoryAssetsInfoPage(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/assetsinfo/queryMyAssetsHandlerPage',
            method: 'post',
        });
    },
    // 询盘
    inquiry(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/trade/inquiry',
            method: 'post',
        });
    },
    // 字典根据code查询子属性详情
    queryOptionTypeList(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/optionType/queryOptionTypeList',
            method: 'post',
        });
    },
    // 字典根据code查询子属性详情
    queyOptinTypeByMarkid(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/optionType/queyOptinTypeByMarkid',
            method: 'post',
        });
    },
    // 我的询盘
    queryMarketInquiryPage(data) {
        return ajax(data, {
            url: getConfig().TShangApi + '/trade/queryMarketInquiryPage',
            method: 'post',
        });
    },
};
