import VueCookie from 'vue-cookie'

// 检查登录状态完整性
const checkLogin = callback => {
  const userId = VueCookie.get('userId')
  // const email = VueCookie.get('email')
  const token = VueCookie.get('client-token')
  const role = VueCookie.get('role')
  // eslint-disable-next-line standard/no-callback-literal
  if (!token || !userId || !role) callback(false)
}

export default checkLogin
