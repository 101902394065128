import Axios from 'axios'
import { Message } from 'element-ui'
import getConfig from '@/config'
import tool from '@/tool'
import Store from '@/store'
const instance = Axios.create({
})
const fileInstance = Axios.create({
  responseType: 'bobl'
})
export const fileInstanceAjax = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    fileInstance({
      url,
      method: 'GET',
      params: data,
      responseType: 'bobl',
      headers: {
        'X-Auth-User': Store.state.user.loginName,
        'X-Auth-Token': Store.state.user.token,
        ...data.headers
      }
    }).then(res => {
      resolve(res.data)
    })
  })
}
const ajax = (data = {}, { url, method, code }, { showErrMsg = true, check401Error = true } = {}) => {
  return new Promise((resolve, reject) => {
    var codeHeaders = code ? { 'X-Menu-Code': code } : {}
    instance({
      url,
      method,
      params: data.query || (method === 'get' ? data : {}),
      data: data.body || (method === 'post' ? data : {}),
      timeout: 1000 * 60,
      headers: {
        ...codeHeaders,
        'X-Auth-User': Store.state.user.loginName,
        'X-Auth-Token': Store.state.user.token,
        'X-Auth-Lang': localStorage.getItem('locale') || 'zh',
        ...data.headers
      }
    }).then(res => {
      if (Number(res.data.code) !== 200) {
        // 业务异常
        // 请在此处处理业务异常
        if (showErrMsg) Message.error(res.data.msg)
        reject(res.data)
        return false
      }
      if (!res.data.encode) {
        resolve(res.data)
      } else {
        const tempRes = res.data
        tempRes.data = JSON.parse(tool.AES.decrypt(res.data.data.replace(/[\r\n]/g, ''), Store.state.user.decodeKey || 'MIICdQIBADANBgkq'))
        // console.log('222', JSON.parse(JSON.stringify(res.data)))
        console.log(tempRes, '解析=>' + res.config.url)
        resolve(tempRes)
      }
    }).catch((err) => {
      if (2 > 3 && url.indexOf('/menu/authMenu') > -1) {

      }
      // 请在此处处理http异常
      else if (err.toString().includes('401') && check401Error) {
        tool.clearLocalData()
        Message.error('登录失效，请重新登录。') // 登录失效，请重新登录。
        window.location.href = getConfig().pathZeus + '/login'
        return false
      }
      reject(err.data)
      if (err.msg === undefined) return false
      if (showErrMsg) Message.warning('网络异常，请稍后再试!') // 网络异常，请稍后再试!
    })
  })
}

export default ajax
