//https://ts.xcatliu.com/advanced/generics.html
import auth from './auth';
import config from './config';
import menu from './menu';
import device from './device';
import organization from './organization';
import user from './user';
import emission from './emission';
import factor from './factor';
import productMerge from './productMerge';
import ledger from './ledger';
import product from './product';
import carbonSmartReduction from './carbonSmartReduction';
import tzh from './tzh';
import price from './price';
import lite from './lite';
import annualPurchase from './annualPurchase';
const modules = {
    auth,
    config,
    menu,
    device,
    organization,
    user,
    emission,
    factor,
    productMerge,
    ledger,
    product,
    tzh,
    price,
    carbonSmartReduction,
    lite,
    annualPurchase
};
// 只取一级目录index.js
// const files = require.context('./', true, /^\.\/(\w*\/)+index\.js$/)
// files.keys().forEach(ele => {
//   if (files(ele).default.moduleName) {
//     modules[files(ele).default.moduleName] = files(ele).default
//   }
// })
export default modules;
