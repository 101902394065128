// import axios from 'axios'
import getConfig from './../config';
import ajax from './ajax';
export default {
    getVersion() {
        // return axios.get(getConfig().room + '/version.json')
        return ajax({}, {
            url: getConfig().room + '/version.json',
            method: 'get'
        });
    },
    postUserMenu(data) {
        return ajax({}, {
            url: getConfig().serverApi + '/menu/queryUserMenuList',
            method: 'post'
        });
    },
    postManagerMenu(data) {
        return ajax({}, {
            url: getConfig().serverApi + '/menu/queryCustomMenuList',
            method: 'post'
        });
    },
    post(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/menu/buildMenu',
            method: 'post'
        });
    },
    postUserInfo(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/userInfo',
            method: 'post'
        });
    },
    postParkParkOut(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/park/parkOut',
            method: 'post'
        });
    },
    postQuerySystemConfig(data, options) {
        return ajax(data, {
            url: getConfig().serverApi + '/systemConfig/querySystemConfig',
            method: 'post'
        }, options);
    },
    postSaveOrUpdateSystemConfig(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/systemConfig/saveOrUpdate',
            method: 'post'
        });
    }
};
