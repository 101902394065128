import ajax from '@/api/ajax';
import getConfig from '@/config';
export default {
    // 部门列表
    queryDeptList(data) {
        return ajax(data, {
            url: getConfig().LiteApi + '/liteDepartment/queryLiteDepartmentList',
            method: 'post'
        });
    },
    // 排放源
    queryEmissionList(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/liteEmissionsSource/queryLiteEmissionsSourceTreeList',
            method: 'post'
        });
    },
    // 查询第二步表格信息
    queryTableData(data) {
        return ajax(data, {
            url: getConfig().LiteApi + '/liteAnnualAccount/quryTableAnnualFilling',
            method: 'post'
        });
    },
    // 保存表格信息
    saveTableData(data) {
        return ajax(data, {
            url: getConfig().LiteApi + '/liteAnnualAccount/saveOrUpdateAnnualAccountDTO',
            method: 'post'
        });
    },
    // 校验用户信息是否完整
    checkRegUser(data) {
        return ajax(data, {
            url: getConfig().LiteApi + '/regUser/checkRegUser',
            method: 'post'
        });
    },
    // 更新用户信息
    updateLiteUser(data) {
        return ajax(data, {
            url: getConfig().LiteApi + '/regUser/updateLiteUser',
            method: 'post'
        });
    },
    // 查询行业列表
    queryIndustryList(data) {
        return ajax(data, {
            url: getConfig().LiteApi + '/liteIndustry/queryLiteIndustryTreeList',
            method: 'post'
        });
    },
    // 查询最后一步图表信息
    queryThreeData(data) {
        return ajax(data, {
            url: getConfig().LiteApi + '/liteAnnualAccount/queryAnnualReportData',
            method: 'post'
        });
    },
    // 根据部门查询排放源
    deptQueryEmission(data) {
        return ajax(data, {
            url: getConfig().LiteApi + '/liteEmissionsSource/queryLiteEmissionsSourceListByDeptid',
            method: 'post'
        });
    },
    // 查询已经填报的年份
    queryLiteEmissionsFinished(data) {
        return ajax(data, {
            url: getConfig().LiteApi + '/liteAnnualAccount/queryLiteEmissionsFinished',
            method: 'post'
        });
    },
    // 查询已经填报的年份
    queryOptionTypeList(data) {
        return ajax(data, {
            url: getConfig().LiteApi + '/optionType/queryOptionTypeList',
            method: 'post',
            code: 'option-type'
        });
    },
    // 根据行业和年份,查询历史记录
    queryAnnualLog(data) {
        return ajax(data, {
            url: getConfig().LiteApi + '/liteAnnualAccount/queryAnnualLog',
            method: 'post'
        });
    },
    // 导出信息
    exportFile(data) {
        return ajax(data, {
            url: getConfig().LiteApi + '/liteAnnualAccount/exportReport',
            method: 'post'
        });
    },
    // 查职位
    queryPositionList(data) {
        return ajax(data, {
            url: getConfig().LiteApi + '/liteposition/queryPositionList',
            method: 'post'
        });
    },
};
