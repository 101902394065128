import ajax from '@/api/ajax';
import getConfig from '@/config';
class Ledger {
    queryAnnualPurchasePage(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/AnnualPurchase/queryAnnualPurchasePage',
            method: 'post'
        });
    }
    queryLedgerEmissionList(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/ledger/queryLedgerEmissionList',
            method: 'post'
        });
    }
    queryLedgerEmissionDetail(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/ledger/queryLedgerEmissionDetail',
            method: 'post'
        });
    }
    saveLedgerEmission(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/ledger/saveLedgerEmission',
            method: 'post'
        });
    }
    updateLedgerEmission(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/ledger/updateLedgerEmission',
            method: 'post'
        });
    }
    saveLedgerOperate(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/ledger/saveLedgerOperate',
            method: 'post'
        });
    }
    findLedgerOperateOrganization(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/ledger/findLedgerOperateOrganization',
            method: 'post'
        });
    }
    queryCustomLedgerEmissionList(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/ledger/queryCustomLedgerEmissionList',
            method: 'post'
        });
    }
    queryCustomProductionList(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/ledger/queryCustomProductionList',
            method: 'post'
        });
    }
    queryLedgerStatistics(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/ledger/queryLedgerStatistics',
            method: 'post'
        });
    }
    queryCheckedLedgerList(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/ledger/queryCheckedLedgerList',
            method: 'post'
        });
    }
    checkLedger(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/ledger/checkLedger',
            method: 'post'
        });
    }
    cancelCheck(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/ledger/cancelCheck',
            method: 'post'
        });
    }
    sendLedgerMail(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/ledger/sendLedgerMail',
            method: 'post'
        });
    }
}
export default new Ledger();
