import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import { zh, en } from 'Lang'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import moment from 'moment'

console.log('VueI18n.prototype.t', VueI18n.prototype)

Vue.use(VueI18n)

moment.locale('zh-cn')
const messages = {
  en: Object.assign(en, enLocale),
  zh: Object.assign(zh, zhLocale)
}
if (!localStorage.getItem('locale')) {
  localStorage.setItem('locale', 'zh')
}
const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'zh',
  messages
})

locale.i18n((key, value) => i18n.t(key, value)) // 为了实现element插件的多语言切换

Vue.prototype.$t = function (key) {
  var values = []; var len = arguments.length - 1
  while (len-- > 0) values[len] = arguments[len + 1]
  var aa = i18n._t.apply(i18n, [key, i18n.locale, i18n._getMessages(), this].concat(values))

  if (typeof aa === 'string') {
    var regex2 = /\[(.+?)\]/g // [] 中括号
    var result = key.match(regex2)
    if (aa === key && result) {
      var r = /\[\'(.+?)\'\]/g
      var m = r.exec(key)
      if (m && m[1]) {
        return m[1]
      } else {
        return key
      }
    } else if (aa.indexOf('.') == -1) {
      return aa
    } else {
      var bb = key.split('.')
      return bb[bb.length - 1]
    }
  } else {
    return aa
  }
}
export default i18n
