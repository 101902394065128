import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookie from 'vue-cookie'
import login from './../views/Login/index.vue'
import register from './../views/Register/index.vue'
import CheckMail from './../views/Login/CheckMail.vue'
import desk from './../views/Desk/index.vue'
import home from './../views/Home/index.vue'
import error from './../layout/error'
import store from '@/store'
import api from '@/api'
import tool from '@/tool'

import tenant from './tenant'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/login'
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    meta: {
      auth: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      auth: false
    }
  },
  {
    path: '/register',
    name: 'register',
    component: register,
    meta: {
      auth: false
    }
  },
  {
    path: '/checkMail',
    name: 'checkMail',
    component: CheckMail,
    meta: {
      auth: false
    }
  },
  {
    path: '/desk',
    name: 'desk',
    component: desk,
    meta: {
      auth: false
    }
  },
  {
    path: '/desk',
    name: 'desk',
    component: desk,
    meta: {
      auth: false
    }
  },
  {
    path: '*',
    name: 'error',
    component: error,
    meta: {
      auth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  /* eslint-disable */
  base: process.env.BASE_URL,
  /* eslint-disable */
  routes
})


// 在路由切换时清除所有未完成的请求
Vue.Cancel = []
router.beforeEach((to, from, next) => {
  if (window.axiosPromiseArr) {
    window.axiosPromiseArr.forEach((ele, index) => {
      ele.cancel()
      delete window.axiosPromiseArr[index]
    })
  }

  var auth = true
  if (typeof to.meta.auth != 'undefined') {
    auth = to.meta.auth
  }
  console.log('auth', auth)
  console.log('to.name', to.name)


  //针对选项卡sessionid
  var sid = ''
  if (sessionStorage.getItem('_sid')) {
    sid = sessionStorage.getItem('_sid');
  }
  else if (to.query.sid) {
    sid = to.query.sid
    sessionStorage.setItem('_sid', sid);
  }


  if (!auth) {
    if (sid && VueCookie.get('client-token')) { //特殊情况处理
      api.menu.querySecondMenuByParentId({ id: sid }).then(res => {
        store.commit('setMenuCode', res.data)
        store.commit('setMenuList', res.data)
        next()
      })
    } else {
      next()
    }
  }
  else if (auth) {

    if(to.name=='home'){
      if(VueCookie.get('client-token')){
        store.commit('setMenuCode', [])
        store.commit('setMenuList', [])
        next()
      }else{
        next('/login')
      }

    }
    else if (store.state.menuCode && store.state.menuCode.length > 0) {
      var aa = store.state.menuCode;
      if (aa.includes(to.name)) {
        next()
      } else {
        next('/forbidden')
      }
    }
    else if (sid && VueCookie.get('client-token')) {
      api.menu.querySecondMenuByParentId({ id: sid }).then(res => {
        store.commit('setMenuCode', res.data)
        store.commit('setMenuList', res.data)
        var aa = store.state.menuCode;//filter(ele => ele.type == 'nav').map(ele=>ele.name);
        if (aa.includes(to.name)) {
          next()
        } else {
          next('/forbidden')
        }
      })
    } else {
      if (sid) {
        next('/login?redirect=' + encodeURIComponent(to.fullPath))

      } else {
        next('/login')
      }
    }

  }

})

// 过滤用户路由
const getRouter = (router, code, ability = '') => {
  const nameList = (ability || '').split(',').filter(item => {
    return item.indexOf(code) === 0
  }).map(item => {
    return item.substring(code.length + 1)
  })

  const filterTree = (list, nameList) => {
    return list.filter(item => {
      return nameList.indexOf(item.name) !== -1 || !item.name
    }).map(item => {
      return {
        ...item,
        children: item.children ? filterTree(item.children, nameList) : []
      }
    })
  }
  return filterTree(router, nameList)
}

//
// if (role === 'BANKUSER' || role === 'SUPERUSER') {
//   router.addRoutes(getRouter(bank, getConfig().name, (LocalStorage.get('ability') || {}).value))
// } else if (role === 'TENANTUSER' || role === 'USER') {
// console.log('LocalStorage.get(\'ability\')',LocalStorage.get('ability'))
//   router.addRoutes(getRouter(tenant, getConfig().name, (LocalStorage.get('ability') || {}).value))
// }

router.addRoutes(tenant)

export default router
