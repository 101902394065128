<template>
  <div class="import-pop" v-if="value">
    <div class="content">
      <div class="close-btn" @click="$emit('input', false)"></div>
      <div class="import-pop-title">导入</div>
      <div class="btn-box">
        <div class="item">
          <div class="title">下载模板:</div>
          <div class="card" @click="downFIle">
            <div class="img"></div>
            <div class="card-tit">点击下载</div>
          </div>
        </div>
        <div class="item">
          <div class="title">导入文件:</div>
          <div class="card" @click="selectFile">
            <div class="img"></div>
            <div class="card-tit">{{ fileName }}</div>
          </div>
        </div>
      </div>
      <div class="footer-btn">
        <el-button
          type="primary"
          plain
          size="medium"
          @click="$emit('input', false)"
        >
          取消</el-button
        >
        <el-button type="primary" size="medium" @click="submit">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    templateName: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      fileName: '选择文件',
      formData: null
    }
  },
  methods: {
    downFIle () {
      const a = document.createElement('a')
      a.download = this.templateName
      a.href = "/tantan-admin/template/" + this.templateName
      a.click()
    },
    selectFile () {
      const inputEL = document.createElement('input')
      inputEL.type = "file"
      inputEL.accept = ".xls,.xlsx"
      inputEL.onchange = (e) => {
        if (e.target.files.length > 0) {
          const file = e.target.files[0]
          this.fileName = file.name
          const fd = new FormData()
          fd.append('file', file)
          this.formData = fd
        }
      }
      inputEL.click()
    },
    submit () {
      if (!this.formData) return this.$message.warning('请选择文件')
      this.$emit('importFile', this.formData)
    }
  }
}
</script>
<style lang="scss" scoped>
.import-pop {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  * {
    box-sizing: border-box;
  }
  .footer-btn {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translate(-50%);
    /deep/.el-button {
      width: 88px;
    }
  }
  .content {
    padding: 24px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 632px;
    height: 468px;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    .close-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 24px;
      height: 24px;
      background: url("~@/assets/img/close-btn.png") no-repeat center/cover;
      cursor: pointer;
    }
    .btn-box {
      display: flex;
      justify-content: space-between;
      margin-top: 74px;
      padding: 0 68px;
      .item {
        width: 188px;
        &:last-child .card .img {
          background: url("~@/assets/img/icon/upload-bg.png") no-repeat
            center/cover;
        }
        .title {
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 24px;
        }
        .card {
          background-color: #f7f8f7;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 140px;
          cursor: pointer;
          user-select: none;
          .img {
            width: 48px;
            height: 48px;
            background: url("~@/assets/img/icon/down-bg.png") no-repeat
              center/cover;
          }
          .card-tit {
            text-align: center;
            margin-top: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular-, PingFangSC-Regular;
            font-weight: normal;
            color: #09a666;
          }
        }
      }
    }
    .import-pop-title {
      text-align: center;
      font-size: 20px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
  }
}
</style>