const recursionFindProp = (tree = [], key = 'value', value = '') => {
  for (const i in tree) {
    // console.log(tree[i], key, '324567', value)
    if (tree[i][key] === value) {
      return [tree[i]]
    }
    if ((tree[i].node || [])) {
      const node = recursionFindProp(tree[i].node, key, value)
      if (node !== undefined) {
        return node.concat(tree[i])
      }
    }
  }
}

export default recursionFindProp
