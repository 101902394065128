import ajax from '@/api/ajax';
import getConfig from '@/config';
export default {
    save(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/product/saveProduct',
            method: 'post',
            code: 'product-merge-add'
        });
    },
    remove(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/product/deleteProductById',
            method: 'post',
            code: 'product-merge-add'
        });
    },
    update(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/product/updateProduct',
            method: 'post',
            code: 'product-merge-add'
        });
    },
    query(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/product/queryProductPage',
            method: 'post',
            code: 'product-merge-list'
        });
    },
    findById(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/product/findProductById',
            method: 'post',
            code: 'product-merge-detils'
        });
    },
    findTypeList(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/productCategory/queryProductCategoryList',
            method: 'post',
            code: 'product-merge-list'
        });
    },
};
