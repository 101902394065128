import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    menuList: [],
    menuCode: [
    ]
  },
  getters: {
  },
  mutations: {
    setMenuList (state, data) {
      if(data){
        state.menuList = data
      }
    },
    setMenuCode (state, data) {
      var codes = []

      function extra (arr) {
        arr.forEach(ele => {
          if (ele.show) {
            codes.push(ele.name)
          }
          if (ele.show && ele.node && ele.node.length > 0) {
            extra(ele.node)
          }
        })
      }
      extra(data)
      state.menuCode = codes
    },
    setUser (state, data) {
      state.user = {
        ...state.user,
        ...data
      }
    },
    clearUser (state, data) {
      state.user = {

      }
    }
  },
  actions: {

  },
  modules: {
  },
  plugins: [
    // 可以有多个持久化实例  这里的数据不要受 resetVuex 影响 永久保留app内
    createPersistedState({
      key: 'YT-user', // 状态保存到本地的 key
      paths: ['user'], // 要持久化的状态，在state里面取，如果有嵌套，可以  a.b.c
      storage: window.localStorage
    })
  ]
})
