<template>
  <div class="g-box g-error-box">
    <template v-if="checking">
      <div class="g-wrapper-txt">
        <span style="font-size: 14px">邮箱认证中...</span>
      </div>
    </template>
    <template v-else>
      <div class="g-wrapper-txt">
        <el-result icon="success"> </el-result>
      </div>
      <div class="g-wrapper">
        <div style="text-align: center">
          <div class="code">邮箱已激活，请用默认密码登录</div>
          <el-button
            size="small"
            style="position: relative; top: 100px"
            @click="handleBack"
            >马上登录</el-button
          >
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import api from '@/api'
export default Vue.extend({
  components: {},
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      checking: true
    }
  },
  computed: {},
  watch: {},
  beforeCreate () {
  },
  created () {
    console.log('r', this.$route)
    // loginName=726012AC67491B9E95F6C953CBE32DE305A1BC98B1E41A301CEAA1298EA3EE26
    var a = this.$route.query.loginName
    if (a) {
      api.auth.checkUserEmail({
        loginName: a
      }).then(res => {
        this.checking = false
      })
    }
  },
  beforeMount () {
  },
  mounted () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    handleBack () {
      this.$router.replace('/login')
    }
  }
})
</script>

<style lang="scss" scoped>
@import "../../assets/css/global-variable.scss";
.g-error-box {
  background: #ffffff;
  position: relative;
  width: 100%;
  height: 100%;
}
.g-wrapper {
  width: 100%;
  height: 300px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.g-wrapper .code {
  font-size: 36px;
  letter-spacing: 0px;
  margin-top: 20px;
  color: $fc-theme;
}
.g-wrapper-txt {
  position: relative;
  color: #666666;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  font-size: 24px;
}
</style>
