import ajax from '@/api/ajax';
import getConfig from '@/config';
import Base from './base';
class Emission extends Base {
    queryPage(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/emission/queryEmissionPage',
            method: 'post',
            code: 'emission-list'
        });
    }
    save(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/emission/saveEmission',
            method: 'post',
            code: 'emission-add'
        });
    }
    update(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/emission/updateEmissionById',
            method: 'post',
            code: 'emission-edit'
        });
    }
    findById(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/emission/findEmissionById',
            method: 'post',
            code: 'emission-edit'
        });
    }
    remove(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/emission/deleteEmissionById',
            method: 'post',
            code: 'emission-delete'
        });
    }
    query(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/emission/queryEmissionList',
            method: 'post',
            code: 'emission-edit'
        });
    }
    updateEmissionStatusById(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/emission/updateEmissionStatusById',
            method: 'post',
            code: 'emission-edit'
        });
    }
}
function ajax2(data, options, show) {
    console.log(222);
    if (data) {
        console.log(222);
    }
    return 14;
}
function createArray(length, value) {
    var result = [];
    for (let i = 0; i < length; i++) {
        result[i] = value;
    }
    return result;
}
var aaa = createArray(12, { x: "xxx", y: "122", z: 12 });
export default new Emission();
