import checkButton from '@/tool/check-button'
import getConfig from '@/config'
import defaultImg from '@/assets/img/default-img.png'
export default {
  directives: {
    clickOutside: { // 是否点在元素外
      bind (el, binding, vnode) {
        vnode.context.globalClick(e => {
          if (!el.contains(e.target)) binding.value()
        })
      },
      role: {
        bind (el, binding, vnode) {
          if (!checkButton(binding.value)) {
            // el.style.opacity="0"
            el.style.display = 'none'
          }
        }
      }
    }
  },
  filters: {
    preUrl (val) {
      if (val) {
        return getConfig().ftpUrl + '/' + val
      }
      return defaultImg
    }
  },
  methods: {
    preUrl (val) {
      if (val) {
        return getConfig().ftpUrl + '/' + val
      }
      return defaultImg
    },
    returnPreviousPage () {
      this.$confirm('是否确认不提交数据关闭？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.go(-1)
      })
    },
    returnUnlimitInputLength () {
      return 120
    },
    returnlimitInputLength () {
      return 30
    },
    pattern (type) {
      let str = null
      switch (type) {
        case 'floatAndZero':
          str = /^(0|[1-9][0-9]*)(\.\d+)?$/
          break
        case 'IntAndZero':
          str = /^(0|[1-9]\d*)$/
          break
      }
      return str
    }
  },
  mounted () { }
}
