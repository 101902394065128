import ajax from '@/api/ajax';
import getConfig from '@/config';
import Base from './base';
class Product extends Base {
    save(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/factor/saveFactor',
            method: 'post',
            code: 'factor-add'
        });
    }
    remove(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/factor/deleteFactorById',
            method: 'post',
            code: 'factor-remove'
        });
    }
    update(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/factor/updateFactor',
            method: 'post',
            code: 'factor-edit'
        });
    }
    queryPage(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/product/queryProductList',
            method: 'post',
            code: 'factor-list'
        });
    }
    findById(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/factor/findFactorById',
            method: 'post',
            code: 'factor-view'
        });
    }
    queryList(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/product/queryProductList',
            method: 'post',
            code: 'emission-add'
        });
    }
}
export default new Product();
