import ajax from '@/api/ajax'
import getConfig from '@/config'
import tool from '@/tool'
export default {
  // 用户登录
  async postAuthLogin (data) {
    let encrypt
    let encrypted
    return new Promise(
      (resolve) => {
        tool
          .loadScript(getConfig().pathZeus + '/jsencrypt.js?' + new Date())
          .then((res) => {
            /* eslint-disable */
            encrypt = new JSEncrypt()
            console.log('data.publicKey',data.publicKey)
            encrypt.setPublicKey(data.publicKey)
            /* eslint-disable */
            encrypted = encrypt.encrypt(data.password)
            resolve(
              ajax({
                ...data,
                password: encrypted,
              }, {
                url: getConfig().serverApi + '/login/userLogin',
                method: 'post'
              })
            )
          })
      },
      (reject) => {
        reject()
      }
    )
  },
  // 获取解密key
  getAesKey (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/login/getAesKey',
      method: 'post'
    })
  },
  // 注册用户
  registerUser (data) {
    return ajax(data, {
      url: getConfig().LiteApi + '/regUser/registerLiteUser',
      method: 'post'
    })
  },
  // 区域树
  queryRegionTreeList (data) {
    return ajax(data, {
      url: getConfig().LiteApi + '/region/queryRegionTreeList',
      method: 'post'
    })
  },
   // 职位
   queryPositionList (data) {
    return ajax(data, {
      url: getConfig().LiteApi + '/liteposition/queryPositionList',
      method: 'post'
    })
  },
  // 期望
  queryIndustryList (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/optionType/queryOptionTypeList',
      method: 'post'
    })
  },
  // 用户验证码登录
  postAuthPhoneLogin (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/login/phoneLogin',
      method: 'post'
    })
  },
  // 获取登录验证码
  getAuthPassCode (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/login/sendLoginVerificationCode',
      method: 'post'
    })
  },
  // 获取注册验证码
  getRegPassCode (data) {
    return ajax(data, {
      url: getConfig().LiteApi + '/regUser/sendRegVerificationCode',
      method: 'post'
    })
  },
  // 保存用户状态
  saveVirtualToken (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/saveVirtualToken',
      method: 'post'
    })
  },
  // 退出登录
  getAuthLogout (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/logout',
      method: 'get'
    })
  },
  // 首页社区以及用户数量统计
  getAuthParkNum (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/parkNum',
      method: 'get'
    })
  },
  // 首页社区展示
  getAuthParkList (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/parkList',
      method: 'get'
    })
  },
  // 社区展示列表
  // getAuthParkList (data) {
  //   return ajax(data, {
  //     url: getConfig().serverPms + '/parkList',
  //     method: 'get'
  //   })
  // },
  // 社区状态修改
  postAuthUpdateParkStatus (data) {
    return ajax(data, {
      url: getConfig().serverApi +
        '/park/updateParkStatus',
      method: 'post'
    })
  },
  // 新增社区以及社区所属用户
  postAuthAddPark (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/custom/saveCustom',
      method: 'post'
    })
  },
  // 社区信息详情
  getAuthFindPark (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/custom/findCustomById',
      method: 'post'
    })
  },
  // 社区信息修改
  postAuthUpdatePark (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/park/updatePark',
      method: 'post'
    })
  },
  // 查询用户列表
  getAuthUserList (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/user/queryUserPage',
      method: 'post'
    })
  },
  // 更新用户状态（停用  启用）
  postAuthUpdateUserStatus (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/user/updateStatusById',
      method: 'post'
    })
  },
  // 添加用户
  postAuthAddUser (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/user/saveUser',
      method: 'post'
    })
  },
  updateStatusById (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/user/updateStatusById',
      method: 'post'
    })
  },
  updateCustomStatusById (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/custom/updateCustomStatusById',
      method: 'post'
    })
  },
  // 删除用户
  getAuthDeleteUser (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/user/deleteUserById',
      method: 'post'
    })
  },
  // 选择租户
  getAuthAllPark (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/allPark',
      method: 'get'
    })
  },
  // 角色列表
  getAuthRoleRoleList (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/role/queryRolePage',
      method: 'post'
    })
  },
  // 新增角色
  postAuthRoleAddRole (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/role/saveRole',
      method: 'post'
    })
  },
  // 编辑角色
  postAuthRoleUpdateRole (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/role/updateRoleById',
      method: 'post'
    })
  },
  // 用户信息详情
  getAuthFindUser (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/user/findUserById',
      method: 'get'
    })
  },
  // 用户编辑修改
  postAuthUpdateUser (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/user/updateUser',
      method: 'post'
    })
  },
  // 删除角色
  getAuthRoleDeleteRole (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/role/deleteRoleById',
      method: 'post'
    })
  },
  // 角色详情
  getAuthRoleFindRole (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/role/findRoleById',
      method: 'post'
    })
  },
  updateOrgStatusById (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/organization/updateOrgStatusById',
      method: 'post'
    })
  },
  // 组织列表
  getAuthOrgList (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/organization/queryOrgPage',
      method: 'post'
    })
  },
  // 删除组织
  postAuthDeleteOrg (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/organization/deleteOrgById',
      method: 'post'
    })
  },
  // 新增组织
  postAuthAddOrg (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/organization/saveOrganization',
      method: 'post'
    })
  },
  // 组织详情
  getAuthFindOrg (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/organization/findOrganizationById',
      method: 'post'
    })
  },
  // 组织修改
  postAuthUpdateOrg (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/organization/updateOrganizationById',
      method: 'post'
    })
  },
  // 日志分页
  getAuthLogList (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/logList',
      method: 'get'
    })
  },
  // 获取当前操作用户组织
  getAuthLevelAllOrg (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/levelAllOrg',
      method: 'get'
    })
  },
  // 获取当前操作用户所有角色信息
  getAuthRoleAllRole (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/role/queryAllRoleList',
      method: 'post'
    })
  },
  // 组织树形数据
  getAuthOrgTree (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/organization/queryOrgTree',
      method: 'post'
    })
  },
  // 获取登录校验
  postAuthSalt (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/login/getPublicKey',
      method: 'post'
    })
  },
  // 获取省市县详情
  getAreaAreaTree (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/area/areaTree',
      method: 'get'
    })
  },
  // 获取社区下辖组织
  getLevelAllOrg (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/organization/queryOrgTree',
      method: 'post'
    })
  },
  // 进入园区功能
  postParkGoParkLook (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/park/inParkById',
      method: 'post'
    })
  },
  // 修改或保存社区信息
  postParkSaveOrUpdate (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/park/saveOrUpdate',
      method: 'post'
    })
  },
  // 社区详情根据ID
  postParkFindById (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/park/findById',
      method: 'post'
    })
  },
  // 园区总览分页查询
  postParkQuery (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/custom/queryCustomPage',
      method: 'post'
    })
  },
  postDataViewHomePageInfo (data) {
    return ajax(data, {
      url: getConfig().serverDataViewApi + '/page/v1/getHomePageInfo',
      method: 'post'
    })
  },
  postManagerHomePageInfo (data) {
    return ajax(data, {
      url: getConfig().serverManagerApi + '/deviceInfo/getHomePageInfo',
      method: 'post'
    })
  },
  postManagerDevicePercentInfo (data) {
    return ajax(data, {
      url: getConfig().serverManagerApi + '/deviceInfo/getDevicePercentInfo',
      method: 'post'
    })
  },
  // 修改user密码
  postUpdatePassWordById (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/user/updatePassWordById',
      method: 'post'
    })
  },
  // 修改密码
  postEditPassword (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/editPassword',
      method: 'post'
    })
  },
  // 重置密码
  postResetPassword (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/resetPassword',
      method: 'post'
    })
  },
  // 字典列表
  postQueryDictList (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/dict/query',
      method: 'post'
    })
  },
  // 删除字典
  postDeleteDictById (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/dict/delete',
      method: 'post'
    })
  },
  // 新增或修改字典
  postAddOrUpdateDict (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/dict/saveOrUpdate',
      method: 'post'
    })
  },
  // 查询字典
  postFindDictById (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/dict/findById',
      method: 'post'
    })
  },
  // 字典查询父级所有子集列表
  postFindDictByParentCode (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/dict/parentPage',
      method: 'post'
    })
  },

  // 字典根据code查询子属性详情
  queryOptionTypeList (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/optionType/queryOptionTypeList',
      method: 'post',
      code: 'option-type'
    })
  },
  // 字典根据code查询子属性详情
  queryList (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/user/queryList',
      method: 'post'
    })
  },
  // 字典根据code查询子属性详情
  getImgCode (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/login/getImgCode',
      method: 'post'
    })
  },
  sendUpdatePasswordVerificationCode (data) {
    return ajax(data, {
      url: getConfig().serverApi + '/user/sendUpdatePasswordVerificationCode',
      method: 'post'
    })
  },
  updatePassWordById(data) {
    return ajax(data, {
      url: getConfig().serverApi + '/user/updatePassWordById',
      method: 'post'
    })
  },
  checkUserEmail(data){
    return ajax(data, {
      url: getConfig().serverApi + '/user/checkUserEmail',
      method: 'get'
    })
  },
  // 首页预约升级到标准版
  upgradeFormalVersion(data){
    return ajax(data, {
      url: getConfig().LiteApi + '/liteAnnualAccount/upgradeFormalVersion',
      method: 'post'
    })
  },
  // 供应商
  // 供应商列表
  querySupplierPage(data){
    return ajax(data, {
      url: getConfig().serverApi + '/supplier/querySupplierPage',
      method: 'post'
    })
  },
  // 新增供应商
  saveSupplier(data){
    return ajax(data, {
      url: getConfig().serverApi + '/supplier/saveSupplier',
      method: 'post'
    })
  },
  // 编辑供应商
  updateSupplierById(data){
    return ajax(data, {
      url: getConfig().serverApi + '/supplier/updateSupplierById',
      method: 'post'
    })
  },
  // 删除供应商
  deleteSupplierById(data){
    return ajax(data, {
      url: getConfig().serverApi + '/supplier/deleteSupplierById',
      method: 'post'
    })
  },
  // 供应商详情
  findSupplierById(data){
    return ajax(data, {
      url: getConfig().serverApi + '/supplier/findSupplierById',
      method: 'post'
    })
  },
}
