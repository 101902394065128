import ajax from '@/api/ajax';
import getConfig from '@/config';
export default {
    // 查询所有组织
    queryAllOrgList(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/organization/queryAllOrgList',
            method: 'post',
            code: 'option-type'
        });
    },
    // 查询组织树
    queryOrgTree(data) {
        return ajax(data, {
            url: getConfig().serverApi + '/organization/queryOrgTree',
            method: 'post',
            code: 'option-type'
        });
    },
    // 查询组织树
    refreshOrgTree(data) {
        return ajax(data, {
            url: getConfig().MIDDLEAPi + '/organizationBase/synOrganization',
            method: 'post',
            code: 'option-type'
        });
    },
};
