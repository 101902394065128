import api from './../api'
import VueCookie from 'vue-cookie'

// 路由找不到
const routerUtils = err => {
  console.log('err', err)
  /* eslint-disable */
  console.log('process.env.VUE_APP_SECRET', process.env.VUE_APP_SECRET)
  /* eslint-disable */
  api.config.getVersion().then(resolve => {
    if (resolve.data.version !== VueCookie.get('version')) {
      VueCookie.set('version', resolve.data.version, 14)
      // window.location.reload()
      console.log('路由找不到')
    }
  })
}

export default routerUtils
