// 企业端
import routerUtils from './../router-utils'

const mainBox = () => import('../../layout/main-layout').catch(() => routerUtils())
const userList = () => import('./../../views/authority-management/user/user-list.vue').catch(() => routerUtils())
const userPwdEdit = () => import('./../../views/authority-management/user/user-pwd-edit.vue').catch(() => routerUtils())
const roleList = () => import('./../../views/authority-management/role/role-list.vue').catch(() => routerUtils())
const roleAdd = () => import('./../../views/authority-management/role/role-add.vue').catch(() => routerUtils())
const organizationList = () => import('./../../views/authority-management/organization/organization-list.vue').catch(() => routerUtils())
const logList = () => import('./../../views/authority-management/log/log-list.vue').catch(() => routerUtils())
const codeList = () => import('./../../views/authority-management/code/code-list.vue').catch(() => routerUtils())
const codeAdd = () => import('./../../views/authority-management/code/code-add.vue').catch(() => routerUtils())
const codeInfoList = () => import('./../../views/authority-management/code/code-info-list.vue').catch(() => routerUtils())
const codeInfoAdd = () => import('./../../views/authority-management/code/code-info-add.vue').catch(() => routerUtils())
const resourcesList = () => import('./../../views/authority-management/resources/resources-list.vue').catch(() => routerUtils())
const resourcesAdd = () => import('./../../views/authority-management/resources/resources-add.vue').catch(() => routerUtils())
const factorList = () => import('./../../views/FactorLibs/list.vue').catch(() => routerUtils())
const productMergeList = () => import('./../../views/ProductMerge/list.vue').catch(() => routerUtils())
const errorSub = () => import('./../../layout/errorSub.vue').catch(() => routerUtils())

const clientLayout = () => import('../../layout/client-layout').catch(() => routerUtils())
const emission = [
  {
    path: '/emission',
    name: 'emission-list',
    meta: {
      title: '排放源管理'
    },
    component: () => import('./../../views/Emission/emission-list.vue').catch(() => routerUtils())
  },
  {
    path: '/emission/emission-add',
    name: 'emission-add',
    meta: {
      cname: 'emission-list', title: '排放源新增'
    },
    component: () => import('./../../views/Emission/emission-add.vue').catch(() => routerUtils())
  },
  {
    path: '/emission/emission-add/:id',
    name: 'emission-edit',
    meta: {
      cname: 'emission-list', title: '排放源编辑'
    },
    component: () => import('./../../views/Emission/emission-add.vue').catch(() => routerUtils())
  },
  {
    path: '/emission/emission-info/:id',
    name: 'emission-info',
    meta: {
      cname: 'emission-list', title: '排放源详情'
    },
    component: () => import('./../../views/Emission/emission-info.vue').catch(() => routerUtils())
  }
]

// 碳交易
const marketPrice = [
  {
    path: '/market',
    name: 'market',
    component: clientLayout,
    children: [
      {
        path: '/market',
        name: 'market',
        meta: {
          title: '碳商', col: 3, auth: false, home: true
        },
        component: () => import('./../../layout/client-grid.vue').catch(() => routerUtils())
      },
      {
        path: '/market-price',
        name: 'market-price',
        meta: {
          title: '碳交易', home: true
        },
        component: () => import('../../views/MarketPrice/index.vue').catch(() => routerUtils())
      }
    ]
  }
]

const bill = [
  {
    path: '/bill',
    name: 'bill',
    component: clientLayout,
    children: [
      {
        path: '/bill',
        name: 'bill',
        meta: {
          title: '碳账本', col: 2, auth: false, home: true
        },
        component: () => import('./../../layout/client-grid.vue').catch(() => routerUtils())
      },
      {
        path: '/bill/write',
        name: 'bill-write',
        meta: {
          title: '碳账本记账'
        },
        component: () => import('./../../views/Bill/bill.vue').catch(() => routerUtils())
      },
      {
        path: '/bill/audit',
        name: 'bill-audit',
        meta: {
          title: '碳账本审核'
        },
        component: () => import('./../../views/Bill/bill-audit.vue').catch(() => routerUtils())
      }
    ]
  }
]
// 产品管理
const product = [
  {
    path: '/product',
    name: 'product',
    component: clientLayout,
    children: [
      {
        path: '/product',
        name: 'product',
        meta: {
          title: '产品碳管理', col: 3, auth: false, home: true
        },
        component: () => import('./../../layout/client-grid.vue').catch(() => routerUtils())
      },
      {
        path: '/product/product-merge-list',
        name: 'product-merge-list',
        meta: {
          title: '产品管理'
        },
        component: productMergeList
      },
      {
        path: '/bom/list',
        name: 'BOM-list',
        meta: {
          title: '物料清单BOM'
        },
        component: () => import('./../../views/Bom/list.vue').catch(() => routerUtils())
      },
      {
        path: '/bom/add',
        name: 'bom-add',
        meta: {
          title: '物料清单BOM'
        },
        component: () => import('./../../views/Bom/add.vue').catch(() => routerUtils())
      },
    ]
  }
]

// 产品管理
const chain = [
  {
    path: '/chain',
    name: 'chain',
    component: clientLayout,
    children: [
      {
        path: '/chain',
        name: 'chain',
        meta: {
          title: '供应链碳管理' ,col: 2, auth: false, home: true
        },
        component: () => import('./../../layout/client-grid.vue').catch(() => routerUtils())
      },
      {
        path: '/supplier',
        name: 'supplier',
        meta: {
          title: '供应商', auth: true,
        },
        component: () => import('./../../views/Supplier/list').catch(() => routerUtils())
      },
      {
        path: '/annual-purchase',
        name: 'annual-purchase',
        meta: {
          title: '年度采购', auth: true,
        },
        component: () => import('./../../views/AnnualPurchase/list').catch(() => routerUtils())
      },
      {
        path: '/annual-purchase/add',
        name: 'annual-purchase-add',
        meta: {
          cname: 'annual-purchase', title: '年度采购新增'
        },
        component: () => import('./../../views/AnnualPurchase/add.vue').catch(() => routerUtils())
      },
      {
        path: '/annual-purchase/add/:id',
        name: 'annual-purchase-edit',
        meta: {
          cname: 'annual-purchase', title: '年度采购编辑'
        },
        component: () => import('./../../views/AnnualPurchase/add.vue').catch(() => routerUtils())
      },
    ]
  }
]

// 碳知乎
const zhihu = [
  {
    path: '/zhihu',
    name: 'zhihu',
    component: clientLayout,
    children: [
      {
        path: '/zhihu',
        name: 'zhihu',
        meta: {
          title: '碳知乎', col: 3, auth: false, home: true
        },
        component: () => import('./../../layout/client-grid.vue').catch(() => routerUtils())
      },
      {
        path: '/onlineLearning',
        name: 'OnlineLearning-list',
        meta: {
          title: '在线学碳'
        },
        component: () => import('./../../views/Tzhihu/OnlineLearning/list.vue').catch(() => routerUtils())
      },
      {
        path: '/onlineLearning-view',
        name: 'OnlineLearning-view',
        meta: {
          title: '在线学碳'
        },
        component: () => import('./../../views/Tzhihu/OnlineLearning/view.vue').catch(() => routerUtils())
      },
      {
        path: '/know-list',
        name: 'know-list',
        meta: {
          title: '碳道'
        },
        component: () => import('./../../views/Tzhihu/know/list.vue').catch(() => routerUtils())
      },
      {
        path: '/consult-list',
        name: 'consult-list',
        meta: {
          title: '碳资讯'
        },
        component: () => import('./../../views/Tzhihu/consult/list.vue').catch(() => routerUtils())
      },
      {
        path: '/consult-view',
        name: 'consult-view',
        meta: {
          title: '碳资讯'
        },
        component: () => import('./../../views/Tzhihu/consult/view.vue').catch(() => routerUtils())
      }
    ]
  }
]

// 碳智减
const intelligent = [
  {
    path: '/intelligent',
    name: 'intelligent',
    component: clientLayout,
    children: [
      {
        path: '',
        name: 'intelligent',
        meta: {
          title: '碳智减', col: 2, auth: false, home: true
        },
        component: () => import('./../../layout/client-grid.vue').catch(() => routerUtils())
      },
      {
        path: '/intelligent/list',
        name: 'intelligent-list',
        meta: {
          title: '碳智减', auth: false, home: true
        },
        component: () => import('../../views/Intelligent/intelligent.vue').catch(() => routerUtils())
      }
    ]
  }
]

//
const report = [
  {
    path: '/report',
    name: 'report',
    component: clientLayout,
    children: [
      {
        path: '',
        name: 'report',
        meta: {
          title: '碳报表', col: 2, auth: false, home: true
        },
        component: () => import('./../../layout/client-grid.vue').catch(() => routerUtils())
      }
    ]
  }
]
const lite = [
  {
    path: '/lite-fill',
    name: 'lite-fill',
    component: clientLayout,
    children: [
      {
        path: '/lite-fill',
        name: 'lite-fill',
        meta: {
          col: 3, auth: false, home: true, isLite: true
        },
        component: () => import('./../../views/LiteFill/index.vue').catch(() => routerUtils())
      },
      {
        path: '/lite-fill-N',
        name: 'lite-fill-N',
        meta: {
          col: 3, auth: false, home: true, isLite: true
        },
        component: () => import('./../../views/LiteFill/liteFile2.vue').catch(() => routerUtils())
      },
      {
        path: '/selectFile',
        name: 'selectFile',
        meta: {
          col: 3, auth: false, home: true, isLite: true
        },
        component: () => import('./../../views/LiteFill/selectFile.vue').catch(() => routerUtils())
      },
    ]
  }
]
const tenant = [
  {
    path: '/forbidden',
    name: 'forbidden',
    component: errorSub,
    meta: {
      forbidden: true,
      auth: false
    }
  },
  ...bill,
  ...marketPrice,
  ...product,
  ...intelligent,
  ...chain,
  ...zhihu,
  ...report,
  ...lite,
  {
    path: '/base',
    name: 'base',
    component: clientLayout,
    children: [
      {
        path: '/base',
        name: 'base',
        meta: {
          title: '基础信息', auth: false, col: 3, home: true
        },
        component: () => import('./../../layout/client-grid.vue').catch(() => routerUtils())
      },
      ...emission,
      // {
      //   path: 'overview/overview-add',
      //   name: 'overview-add',
      //   component: overviewAdd
      // },
      // {
      //   path: 'overview/overview-info',
      //   name: 'overview-info',
      //   component: overviewAdd
      // },
      // {
      //   path: 'overview/overview-edit',
      //   name: 'overview-edit',
      //   component: overviewAdd
      // },
      // {
      //   path: 'overview-big-screen/overview-big-screen-list',
      //   name: 'overview-big-screen-list',
      //   component: overviewBigScreenList
      // },
      // {
      //   path: 'overview-big-screen/overview-big-screen-add',
      //   name: 'overview-big-screen-add',
      //   component: overviewBigScreenAdd
      // },
      {
        path: '/authority-management',
        redirect: '/authority-management/user/user-list'
      },
      {
        path: '/authority-management/user/user-list',
        name: 'user-list',
        meta: {
          title: '用户管理'
        },
        component: userList
      },
      {
        path: '/authority-management/user/user-list/user-pwd-edit/:id',
        name: 'user-pwd-edit',
        meta: {
          title: '用户管理编辑'
        },
        component: userPwdEdit
      },
      {
        path: '/authority-management/role',
        redirect: '/authority-management/role/role-list'
      },
      {
        path: '/authority-management/role/role-list',
        name: 'role-list',
        meta: {
          title: '角色管理'
        },
        component: roleList
      },
      {
        path: '/authority-management/role/role-add',
        name: 'role-add',
        meta: {
          title: '角色管理新增'
        },
        component: roleAdd
      },
      {
        path: '/authority-management/role/role-list/role-edit/:id',
        name: 'role-edit',
        meta: {
          title: '角色管理编辑'
        },
        component: roleAdd
      },
      {
        path: '/authority-management/role/role-list/role-info/:id',
        name: 'role-info',
        meta: {
          title: '角色管理详情'
        },
        component: roleAdd
      },
      {
        path: '/authority-management/organization',
        redirect: '/authority-management/organization/organization-list'
      },
      {
        path: '/authority-management/organization/organization-list',
        name: 'organization-list',
        meta: {
          title: '组织管理'
        },
        component: organizationList
      },
      {
        path: '/authority-management/log',
        redirect: '/authority-management/log/log-list'
      },
      {
        path: '/authority-management/log/log-list',
        name: 'log-list',
        component: logList
      },
      {
        path: '/authority-management/code',
        redirect: '/authority-management/code/code-list'
      },
      {
        path: '/authority-management/code/code-list',
        name: 'code-list',
        component: codeList
      },
      {
        path: '/authority-management/code/code-add',
        name: 'code-add',
        component: codeAdd
      },
      {
        path: '/authority-management/code/code-list/code-edit/:id',
        name: 'code-edit',
        component: codeAdd
      },
      {
        path: '/authority-management/code/code-info/:id',
        name: 'code-info-list',
        component: codeInfoList
      },
      {
        path: '/authority-management/code/code-info/code-info-add',
        name: 'code-info-add',
        component: codeInfoAdd
      },
      {
        path: '/authority-management/code/code-info/code-info-edit',
        name: 'code-info-edit',
        component: codeInfoAdd
      },
      {
        path: '/authority-management/code/code-info/code-info-info',
        name: 'code-info-info',
        component: codeInfoAdd
      },
      {
        path: '/authority-management/resources',
        redirect: '/authority-management/resources/resources-list'
      },
      {
        path: '/authority-management/resources/resources-list',
        name: 'resources-list',
        component: resourcesList
      },
      {
        path: '/authority-management/resources/resources-add',
        name: '/resources-add',
        component: resourcesAdd
      },
      {
        path: '/authority-management/resources/resources-list/resources-edit/:id/:roleId',
        name: 'resources-edit',
        component: resourcesAdd
      },
      {
        path: '/authority-management/resources/resources-list/resources-info/:id/:roleId',
        name: 'resources-info',
        component: resourcesAdd
      },
      // 因子库
      {
        path: '/factor-list',
        name: 'factor-list',
        component: factorList,
        meta: {
          title: '因子库'
        }
      },
      {
        path: '*',
        name: 'errorSub',
        component: errorSub,
        meta: {
          auth: false
        }
      }
    ]
  }
]

export default tenant
