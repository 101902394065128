<template>
  <div class="g-box g-login-box">
    <div id="myCanvas-wrap" class="">
      <div class="home-bg"></div>
      <div id="myCanvas">
        <div class="login-logo"></div>
        <div class="co2e-box"></div>
        <div class="g-login-box theme-light">
            <div class="u-box">
          <div class="u-reg-tit" style="font-weight: bold">注册</div>
          <div class="u-it u-phone" style="margin-bottom: 24px">
            <input
              class="u-it-in"
              v-model="ruleForm.phone"
              placeholder="请输入手机号码"
            />
            <span class="u-tips">{{ ruleForm.phoneTips }}</span>
          </div>
          <div style="height: 18px"></div>
          <div class="u-it u-password u-code">
            <input
              class="u-it-in"
              v-model="ruleForm.phoneCode"
              placeholder="请输入系统注册验证码"
              @keyup.enter="login"
            />
            <div
              class="u-ver-btn"
              @click="getCode"
              :style="{ background: !validCode ? '#fff' : '#c8c9cc' }"
            >
              {{ validCode ? countdown : "获取验证码" }}
            </div>
          </div>
          <div style="height: 30px"></div>
          <el-button
            class="u-btn"
            type="primary"
            @click="login"
            :loading="loading"
            >注 册</el-button>
          <div class="u-reg-text" @click="$router.push('/login')">
              <span style="color: #0483EFFF"> 已有账号？请登录</span>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import api from './../../api'
import getConfig from './../../config'

import { Notification } from 'element-ui'
// import loadScene from './../../tool/canvas'
import md5 from 'js-md5'
export default Vue.extend({
  name: 'home',
  components: {},
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      ruleForm: {
        phone: '',
        phoneCode: ''
      },
      filter: {},
      interval: {},
      homePath: '',
      loading: false,
      countdown: 0,
      validCode: false,
      interval: null
    }
  },
  watch: {},
  beforeCreate () { },
  beforeDestroy () {
    clearInterval(this.interval)
    // this.deleteGlobalResize('login')
  },
  beforeMount () { },
  mounted () {
    this.resizeCanvas()

    window.addEventListener('resize', _ => this.resizeCanvas(), false)
  },
  destroyed () { },
  methods: {
    getCode () {
      if (this.validCode) return
      if (!this.ruleForm.phone) return this.$message.warning(this.$t('Login.phoneMsg'))
      if (!(/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(this.ruleForm.phone))) return this.$message.warning(this.$t('Login.phoneErrMsg'))
      api.auth.getRegPassCode({
        phone: this.ruleForm.phone
      }).then(res => {
        this.$message.success(this.$t('Login.sendOk'))
        clearInterval(this.interval)
        this.countdown = 60
        this.validCode = true
        this.interval = setInterval(() => {
          if (this.countdown) {
            this.countdown--
          } else {
            this.countdown = 60
            this.validCode = false
          }
        }, 1000)
      })
    },
    resizeCanvas (scale) {
      var wrap = document.querySelector('#myCanvas-wrap')
      var s = document.querySelector('#myCanvas')

      var pw = 1440 // 设计图的宽
      var ph = 900 // 设计图的宽
      var tww = wrap.offsetWidth

      var twh = wrap.offsetHeight

      var scaleCenter = '0% 0%'

      var tw = 0
      var th = 0

      var wsc = 1 // 获取宽比例

      if (pw / tww * twh > ph) {
        if (tww / pw > 1) {
          console.log('超容器适配')
          scaleCenter = '50% 50%'
          wsc = tww / pw
          th = Math.max(0, (twh - ph) * 0.5)
          tw = Math.max(0, (tww - pw) * 0.5)
        } else {
          console.log('宽适配')
          scaleCenter = '0% 0%'
          wsc = tww / pw
          th = ph < twh ? Math.min(0, -(ph - twh) * 0.5) : Math.max(0, (twh - ph) * 0.5)
          tw = 0 // Math.min(0, (pw - tww) * 0.5);
        }
      } else if (ph / twh * tww >= pw) {
        if (twh / ph > 1) {
          console.log('超容器适配')
          scaleCenter = '50% 50%'
          wsc = twh / ph
          th = Math.max(0, (twh - ph) * 0.5)
          tw = Math.max(0, (tww - pw) * 0.5)
        } else {
          console.log('高适配')
          scaleCenter = '50% 0%'
          wsc = twh / ph // 获取宽比例
          th = Math.min(0, (ph - twh) * 0.5)
          tw = pw > tww ? Math.min(0, -(pw - tww) * 0.5) : Math.max(0, (tww - pw) * 0.5)
        }
      }

      // 先宽自适应 wscale
      s.style.transformOrigin = scaleCenter
      s.style.webkitTransformOrigin = scaleCenter
      s.style.transform = 'translate3d(' + 0 + 'px, 0px, 0px) scale(' + wsc + ')'
      s.style.webkitTransform = 'translate3d(' + 0 + 'px,0px, 0px) scale(' + wsc + ')'
      s.style.marginTop = th + 'px'
      s.style.marginLeft = tw + 'px'

      console.log('重置大小 ', wsc)
      this.$emit('resize', wsc)
    },
    login () {
      if (!this.ruleForm.phone) return this.$message.warning(this.$t('Login.phoneMsg'))
      if (!(/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(this.ruleForm.phone))) return this.$message.warning(this.$t('Login.phoneErrMsg'))
      if (!this.ruleForm.phoneCode) return this.$message.warning(this.$t('Login.phoneCodeErrMsg'))
      api.auth.registerUser({
        ...this.ruleForm
      }).then((res) => {
        this.$message.success('注册成功，请用手机验证码登录')
        this.$router.push('/login?phone=1')
      })
    }
  }
})
</script>

<style lang="scss" scoped>
@import "./../../assets/css/global-variable";
$lg-bg: "../../assets/img/login/lg-bg.png";
$lg-icon-account: "../../assets/img/login/new-icon-account.png";
$lg-icon-password: "../../assets/img/login/new-icon-password.png";
$lg-icon-phone: "../../assets/img/login/new-icon-phone.png";
.u-reg-tit {
  padding-top:0px;
  padding-bottom: 20px;
  text-align: center;
  font-size: 20px;
  color: #000000a6;
  font-weight: bold;
}
input::placeholder {
  font-size: 16px;
  line-height: 40px;
}
.u-reg-text {
  margin-top: 19px;
  font-size: 16px;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  font-weight: normal;
  color: #09a666;
  line-height: 24px;
  cursor: pointer;
  user-select: none;
}

#myCanvas-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  background: linear-gradient(-45deg, #202f69, #1b1c4c);
}

.home-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  background: url("~assets/img/login/login-repeat.png") repeat;
}
#myCanvas {
  display: block;
  position: relative;
  width: 1440px;
  height: 900px;
  position: absolute;
  background-size: 1440px;
  .login-logo {
    position: absolute;
    top: 55px;
    left: 0px;
    width: 129px;
    height: 46px;
    background: url("~assets/img/login/login-logo.png") no-repeat;
    background-size: cover;
  }

  .co2e-box {
    position: absolute;
    top: 155px;
    left: 80px;
    width: 536px;
    height: 584px;
    background: url("~assets/img/login/co2e.png") no-repeat;
    background-size: cover;
  }
}

.g-login-box {
  * {
    box-sizing: border-box;
  }

  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 1440px;
  .c {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    .mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 30;
    }
    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background:  url("~assets/img/login/login-bg.png?v=3") no-repeat
        center/ 100% 100%;
    }
    .header {
      width: 100%;
      max-width: 1920px;
      height: 80px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: url($publicPath + "/bg/header.png") no-repeat;
      background-size: 100%;
      z-index: 99;
    }
    .header-title {
      text-align: center;
      line-height: 50px;
      font-size: 24px;
    }
  }
  .u-top-logo {
    width: 156px;
    height: 26px;
    position: absolute;
    left: 30px;
    top: 20px;
  }
  .u-center-logo {
    width: 430px;
    height: 44px;
    position: absolute;
    left: 25%;
    top: 50%;
    transform: translate(-50%);
  }
  .u-nav {
    width: 330px;
    height: 30px;
    position: absolute;
    top: 30px;
    right: 30px;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
  .u-box {
    box-sizing: border-box;
    position: absolute;
    left: 60%;
    top: 50%;
    padding: 30px 40px 40px 40px;
    width: 480px;
    min-height: 410px;
    background: #ffffff;
    box-shadow: 0px 10px 30px 1px rgba(0, 0, 0, 0.03);
    border-radius: 16px 16px 16px 16px;
    box-sizing: border-box;
    z-index: 99;
    transform: translateY(-50%);
    /deep/.el-tabs {
      .el-tabs__content {
        padding-top: 39px;
      }
      .el-tabs__nav-wrap::after {
        display: none;
      }
      .el-tabs__item {
        font-size: 20px;
        font-family: PingFangSC-Medium-, PingFangSC-Medium;
        font-weight: normal;
        color: #09a666;
      }
    }
    /deep/.el-radio-group {
      padding-left: 12px;
      margin-bottom: 24px;
    }
    .u-it {
      position: relative;
      margin-bottom: 20px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #d9d9d9;
      &:after {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        top: 10px;
        left: 10px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      &.u-account {
        &:after {
          background-image: url($lg-icon-account);
        }
      }
      &.u-password {
        display: flex;
        justify-content: space-between;
        margin-top: 1px;
        /* &.u-code {
          border: none;
          .u-it-in {
            margin-left: 0;
            padding-left: 36px;
            border-radius: 4px;
            border: 1px solid #d9d9d9;
            margin-right: 12px;
          } */
        /* } */
        &:after {
          background-image: url($lg-icon-password);
        }
        .u-ver-btn {
          position: relative;
          &[disabled="disabled"] {
            opacity: 0.8;
            background-color: #ffffff;
            height: 32px;
            color: #0483efff;
            height: 32px;
          }
          &::before {
            top: 7px;
            position: absolute;
            left: 0px;
            content: "";
            height: 25px;
            width: 1px;
            background-color: #d9d9d9;
          }
          font-size: 16px;
          user-select: none;
          font-size: 16px;
          color: #0483efff;
          text-align: center;
          width: 111px;
          line-height: 40px;
          /* background: #ffffff; */
          cursor: pointer;
        }
      }
      &.u-phone {
        &:after {
          background-image: url($lg-icon-phone);
        }
      }
      .u-it-in {
        width: calc(100% - 36px);
        flex: 1;
        text-indent: 8px;
        height: 40px;
        margin-left: 36px;
        background-color: transparent;
        outline: none;
        box-shadow: none;
        border: none;
      }
      input.u-it-in::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.25);
      }
      .u-ver {
        margin-right: 10px;
        flex: 2;
        .u-it-in {
          width: 180px;
        }
      }
      .u-tips {
        position: absolute;
        top: 45px;
        left: 0;
        color: $fc-danger;
      }
    }
    .u-remenber {
      .el-checkbox {
        color: #333333;
      }
    }
    .u-operating {
      margin-left: 12px;
      display: flex;
      margin-bottom: 25px;
      justify-content: space-between;
      font-size: $fs-body;
      color: #333333;
      .u-retrieve {
        cursor: pointer;
        color: #09a666;
      }
    }
    .u-btn {
      margin-top: 38px;
      width: 100%;
    }
  }
}
</style>
